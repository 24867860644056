import React from "react";
import {styled} from '@mui/material/styles';
import {Card, CardContent, Container, Grid, Typography, useMediaQuery, useTheme,} from "@mui/material";
import PageHeader from "../elements/PageHeader";
import ISO27000Info from "../elements/ISO27000Info";
import ImageViewer from "../../services/ImageViewer";
import {isIE} from "react-device-detect";
import Diagram from "../../resources/iso27000/ISO27000.png";
import {useDocumentTitle} from "../../services/DocumentTitle";

const PREFIX = 'ISO27000Coaching';

const classes = {
    body: `${PREFIX}-body`
};

const Root = styled('div')(({theme}) => ({
    [`& .${classes.body}`]: {
        backgroundColor: theme.palette.background.default,
        padding: "25px 0 25px 0"
    }
}));

export default function ISO27000Coaching() {

    useDocumentTitle("ISO-27000 Coaching", "Wir führen Sie, trotz spezifisch auf Sie abgestimmten Dienstleistungen, schnell und kostengünstig zu Ihrer ISO 27000 Zertifizierung. Hierfür verfügen wir über ausgewiesene Experten im Bereich der ISO 27000 Standards und deren Anwendung.")

    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Root>
            <PageHeader whiteTitle="ISO-27000 "
                        blueTitle="Coaching"
                        subtitle="Qualitäts Zertifizierung für Ihr Unternehmen"/>
            <div className={classes.body}>
                <Container maxWidth="lg">
                    <div id="iso27000" style={{position: "relative", top: -110}}/>
                    <Grid container>
                        <Grid item xs={12}>
                            {isSmallScreen ? '' :
                                <ISO27000Info/>
                            }
                            <Typography variant="h6">
                                <strong>
                                    Unsere Expertise
                                </strong>
                            </Typography>
                            <Typography variant="body1">
                                Wir führen Sie, trotz spezifisch auf Sie abgestimmten
                                Dienstleistungen, schnell und kostengünstig zu Ihrer ISO 27000 Zertifizierung.
                                Hierfür verfügen wir über ausgewiesene Experten im Bereich der ISO 27000 Standards und
                                deren Anwendung. Unsere Expertise konnten wir bei der erfolgreichen Zertifizierung von
                                Unternehmen vertiefen. Infolgedessen kennen wir eine Vielzahl technischer Systeme und
                                deren Schwachstellen. Dadurch sind wir fähig Sie zu beraten, wie Sie Ihre Systeme in
                                einem ISO 27000 konformen Betrieb einsetzen können.<br/><br/>
                            </Typography>

                            <Typography variant="h6">
                                <strong>
                                    Unser Kredo
                                </strong>
                            </Typography>
                            <Typography variant="body1">
                                Bei all unseren Beratungsmandaten sind wir stets darauf bedacht, uns in die Situation
                                Ihres Unternehmens zu versetzen. So können wir effiziente und günstige Wege erarbeiten,
                                wie Sie baldmöglichst Ihr Unternehmen mit einer ISO 27000 Zertifizierung weiter
                                aufwerten können.<br/>

                                Dazu erörtern wir im Dialog mit Ihnen die Anforderungen und Massnahmen gemäss ISO 27001
                                und ISO 27002. Diese werden dabei festgelegt und zugleich dokumentiert.
                                Parallel dazu werden laufend die „Nichtkonformitäten“ eruiert, die bis zum Audit behoben
                                oder mindestens angegangen sein müssen. Diese Nichtkonformitäten können sowohl
                                technischer Natur (z.B. veraltete, nicht gepatchte Serversysteme) als auch
                                organisatorischer Natur (z.B. mangelhafte Überprüfung neuer Mitarbeiter vor dem
                                Eintritt) sein.<br/><br/>
                            </Typography>

                            {isSmallScreen ?
                                <div style={{width: "100%", display: "flex", justifyContent: "center"}}><ISO27000Info/>
                                </div> : ''
                            }

                            <Typography variant="h6">
                                <strong>
                                    Gemeinsame Zusammenarbeit
                                </strong>
                            </Typography>
                            <Typography variant="body1">
                                Im Verlauf der gemeinsamen Projektarbeit werden Stück um Stück die organisatorischen und
                                administrativen Prozesse eingeführt, die durch ein ISO-basiertes Management System
                                vorgegeben sind.<br/><br/>

                                Sie als Auftraggeber sind von Anfang bis Ende involviert. Dabei haben Sie die volle
                                Kontrolle über die Kosten und den Zeithorizont des Projektes. Ihre Bereitschaft aktiv
                                engagiert zu sein beschleunigt hierbei den Zertifizierungsprozess massgeblich. Auf Ihren
                                Wunsch bieten wir Ihnen Unterstützung in Teilgebieten, die Ihr Unternehmen nicht selbst
                                erarbeiten kann. Wenn Sie das wünschen können wir ausserdem die Projektleitung bis zur
                                Zertifizierung übernehmen.<br/><br/>
                            </Typography>

                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Card elevation={4} style={{maxWidth: 1000}}>
                                    <CardContent style={{padding: "10px"}}>
                                        <div style={isIE && isSmallScreen ? {margin: "auto", left: 0, right: 0, width: 400} : {display: "flex", justifyContent: "center", margin: "auto"}}>
                                            <ImageViewer image={Diagram} alt="ISO-27000 Zertifizierung"/>
                                        </div>
                                        <Typography align="right" variant="subtitle2">
                                            <cite>
                                                ISO-27000 Zertifizierungsprozess
                                            </cite>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>

                            <Typography variant="h6" style={{paddingTop: "20px"}}>
                                <strong>
                                    Ihr Mehrwert
                                </strong>
                            </Typography>
                            <Typography variant="body1">
                                Die ISO 27000 Zertifizierung lohnt sich für viele Firmen. Sie verleihen damit Ihrem
                                Unternehmen ein Qualitätssiegel, welches das Vertrauen Ihrer Kunden in Sie weiter
                                steigert. Gerade für Finanzdienstleister, Produzenten von technischen Geräten,
                                Software-Entwickler oder Lieferanten von öffentlichen Verwaltungen bedeutet die ISO
                                27000 Zertifizierung einen bedeutsamen Wettbewerbsvorteil.<br/><br/>
                            </Typography>
                            <Typography variant="body1" align="center">
                                <cite>
                                    <strong>
                                        Ihr Lead Auditor:&nbsp;
                                    </strong>
                                    Walter Gygli, Dipl. Ing ETH, eidg. dipl. Betriebsökonom,
                                    ISO 27000 Lead Auditor, CISM
                                </cite>
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Root>
    );
}