import React from 'react';
import {styled} from '@mui/material/styles';
import {Button, Divider, Drawer, Fab, List, ListItem, Typography} from "@mui/material";
import {Link} from "react-router-dom";

import MenuIcon from '@mui/icons-material/Menu';

const PREFIX = 'SideDrawer';

const classes = {
    fabPos: `${PREFIX}-fabPos`,
    fab: `${PREFIX}-fab`,
    button: `${PREFIX}-button`,
    drawer: `${PREFIX}-drawer`
};

const Root = styled('div')(({theme}) => ({
    [`& .${classes.fabPos}`]: {
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "8px"
    },

    [`& .${classes.fab}`]: {
        backgroundColor: theme.palette.secondary.light
    },

    [`& .${classes.button}`]: {
        textTransform: "none",
        color: "#000"
    },

    [`& .${classes.drawer}`]: {
        height: "110%",
    }
}));


export default function SideDrawer() {

    const [openDrawer, setOpenDrawer] = React.useState(false);

    const toggleDrawer = () => {
        if (!openDrawer) {
            setOpenDrawer(true);
        } else {
            setOpenDrawer(false);
            window.scrollTo(0, 0)
        }
    };


    return (
        <Root>
            <div className={classes.fabPos}>
                <Fab className={classes.fab} onClick={toggleDrawer}>
                    <MenuIcon color="primary"/>
                </Fab>
            </div>
            <Drawer anchor="left"
                    open={openDrawer}
                    onClose={toggleDrawer}
                    disableScrollLock
                    classes={{
                        paper: classes.drawer,
                    }}
                // ModalProps={{BackdropProps: { root: classes.drawer}}}
            >
                <Root>
                    <List>
                        <ListItem>
                            <List>
                                <ListItem style={{padding: "6px 8px"}}>
                                    <Typography variant="h6">
                                        Kompetenzen
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Button component={Link}
                                            to="/consulting"
                                            className={classes.button}
                                            onClick={toggleDrawer}
                                    >
                                        Sicherheitsberatung
                                    </Button>
                                </ListItem>
                                <ListItem>
                                    <Button component={Link}
                                            to="/engineering"
                                            className={classes.button}
                                            onClick={toggleDrawer}
                                    >
                                        Engineering
                                    </Button>
                                </ListItem>
                                <ListItem>
                                    <Button component={Link}
                                            to="/iso"
                                            className={classes.button}
                                            onClick={toggleDrawer}
                                    >
                                        ISO-27000 Coaching
                                    </Button>
                                </ListItem>
                            </List>
                        </ListItem>

                        <Divider/>

                        <ListItem>
                            <List>
                                <ListItem style={{padding: "6px 8px"}}>
                                    <Typography variant="h6">
                                        Lösungen
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Button component={Link}
                                            to="/secasign"
                                            className={classes.button}
                                            onClick={toggleDrawer}
                                    >
                                        Secasign
                                    </Button>
                                </ListItem>
                                <ListItem>
                                    <Button component={Link}
                                            to="/secasign-cli"
                                            className={classes.button}
                                            onClick={toggleDrawer}
                                    >
                                        Secasign-CLI
                                    </Button>
                                </ListItem>
                            </List>
                        </ListItem>

                        <Divider/>

                        <ListItem>
                            <List>
                                <ListItem style={{padding: "6px 8px"}}>
                                    <Typography variant="h6">
                                        Firma
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Button component={Link}
                                            to="/company"
                                            className={classes.button}
                                            onClick={toggleDrawer}
                                    >
                                        Secacon
                                    </Button>
                                </ListItem>
                                <ListItem>
                                    <Button component={Link}
                                            to="/contact"
                                            className={classes.button}
                                            onClick={toggleDrawer}
                                    >
                                        Kontakt
                                    </Button>
                                </ListItem>
                            </List>
                        </ListItem>

                        <ListItem style={{height: "10%"}}/>
                    </List>
                </Root>
            </Drawer>
        </Root>
    );
}
