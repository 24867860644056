import React from "react";
import {styled} from '@mui/material/styles';
import {Container, Grid, Typography,} from "@mui/material";

import EngineeringCard from "../elements/EngineeringCard";
import PageHeader from "../elements/PageHeader";
import {Link} from "react-router-dom";

import {EngineeringCardModel} from "../../services/Models";

import CreateIcon from "@mui/icons-material/Create";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import SettingsIcon from "@mui/icons-material/Settings";
import {useDocumentTitle} from "../../services/DocumentTitle";


const PREFIX = 'Engineering';

const classes = {
    body: `${PREFIX}-body`,
    title: `${PREFIX}-title`,
    typography: `${PREFIX}-typography`
};

const Root = styled('div')(({theme}) => ({
    [`& .${classes.body}`]: {
        backgroundColor: theme.palette.background.default,
        padding: "25px 0 25px 0"
    },

    [`& .${classes.title}`]: {
        display: "flex",
        justifyContent: "center",
    },

    [`& .${classes.typography}`]: {
        maxWidth: "100%",
        display: "flex",
        alignSelf: "center",
        paddingTop: "20px",
    }
}));

const cardContent = [
    {
        title: "Hardware Security Module",
        description: "Benötigen Sie Hilfe beim Betrieb von HSMs oder wünschen eine spezifsche Funktionserweiterung? Durch unsere langjährige Erfahrung und Partnerschaft mit Gemalto (ehemals Safenet) können wir sie kompetent unterstützen:",
        icon: <VerifiedUserIcon fontSize="large" style={{color: "#323232"}}/>,
        list: true,
        info: ["Bereitstellung eines eigenen HSMs auf Basis von Gemalto HSM-Hardware", "Entwicklung von eigenständigen Functional Modules (FM) für ihren HSM Typ", "Testsoftware für HSMs auf PKCS#11/APDU Ebene"],
        reference: "Modernisierung einer 25 Jahr alten Ausstellungssoftware von Tankkärtchen für Geschäftskunden. Reverse Engineering und Neuentwicklung eines Functional Modules zur Kommunikation zwischen HSM und Drucker",
    },
    {
        title: "Digitale Signaturen",
        description: "Möchten Sie ihre Dokumente und Dateien mit digitalen Signaturen vor unbekannten Manipulationen schützen? Möchten Sie diese Signierung in Ihr Produkt integrieren? Wir bieten eine Vielzahl von Bibliotheken und Lösungen an.",
        icon: <CreateIcon fontSize="large" style={{color: "#323232"}}/>,
        list: true,
        info: ["Digitale Signerung von Dokumenten mit HSMs und USB Token", "Ansteuerung von Smartcards"],
        reference: <>Entwicklung und Betrieb einer Software zur digitalen Signierung von Dokumenten (<Link
            to="/secasign-cli" style={{
            textDecoration: "none",
            color: "#3986d0"
        }}>Secasign-CLI</Link>). Bisher wurden über 135 Millionen Dokumente digital
            signiert und Manipulationen im Gebiet der Lebensmittelanalyse durch Zulieferer erkannt.</>,
    },
    {
        title: "Auftragsentwicklung",
        description: "Unser Engineering bezieht sich jedoch nicht nur auf HSMs und digitale Signaturen, sondern umfasst auch andere Bereiche im Gebiet der IT Security und Kryptografie. Gerne unterstützen wir Sie in der Konzipierung/Entwicklung von Individualsoftware.",
        icon: <SettingsIcon fontSize="large" style={{color: "#323232"}}/>,
        list: true,
        info: ["Konzipierung von Neuentwicklungen oder Ablösen bisheriger Lösungen", "Entwicklung und Umsetzung", "Weiterentwicklung"],
        reference: <>
            Projektberatung und -begleitung sowie Entwicklung für Versicherungen, Banken, Kantons- und Bundesbehörden
            und Militär. Umsetzung eines Proxies zur Kommunikation zwischen Firewall und Versichertenkarte (VeKa).
        </>,
    },
]

export default function Engineering() {

    useDocumentTitle("Engineering", "Dank jahrzehntelanger Erfahrung im Bereich IT-Security Engineering sind wir in der Lage Ihre Anforderungen für Ihr Projekt schnell und flexibel aufzunehmen und umzusetzen. Unsere Spezialgebiete sind, digitale Signaturen und Hardware Security Module (z.B. Functional Module), Authentity Management und Windows-Treiber Entwicklung.");
    
    return (
        <Root>
            <PageHeader whiteTitle="Enginee"
                        blueTitle="ring"
                        subtitle="Wir entwickeln Ihre Software"/>
            <div className={classes.body}>
                <Container maxWidth="lg" style={{display: "flex", flexDirection: "column"}}>
                    <Grid container spacing={3}>
                        {cardContent.map((content: EngineeringCardModel) =>
                            <EngineeringCard
                                title={content.title}
                                description={content.description}
                                info={content.info}
                                list={content.list}
                                icon={content.icon}
                                key={content.title}
                                reference={content.reference}
                            />
                        )}
                    </Grid>
                    <Typography variant="body1" className={classes.typography}>
                        Dank jahrzehntelanger Erfahrung im Bereich IT-Security Engineering sind wir in der Lage Ihre
                        Anforderungen für Ihr Projekt schnell und flexibel aufzunehmen und umzusetzen.
                        Unsere Spezialgebiete sind, digitale Signaturen und Hardware Security Module (z.B. Functional
                        Module), Authentity Management und Windows-Treiber Entwicklung. Unser Wissen ist jedoch nicht
                        auf diese Gebiete beschränkt.
                    </Typography>

                    <Typography variant="body1" className={classes.typography}>
                        Wir unterstützen Sie gerne bei Produkterweiterungen und -vervollständigungen. Auch für
                        Migrationen auf aktuelle Plattformen oder Hardware Security Module sind Sie bei uns in besten
                        Händen. Des Weiteren sind wir durch unser einzigartiges Fachwissen bestens geeignet für die
                        Erarbeitung und Entwicklung von individualisierten Teil- und Gesamtlösungen.
                        Kontaktieren Sie uns ungeniert, wenn Sie eine eigens für Sie entworfene und auf Ihre Bedürfnisse
                        abgestimmte Lösung im IT-Security Bereich entwickeln möchten.
                    </Typography>
                </Container>
            </div>
        </Root>
    );
}