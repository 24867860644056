import React from "react";
import {styled, useTheme} from '@mui/material/styles';
import {Card, CardContent, Grid, Typography, useMediaQuery} from "@mui/material";
import {useDocumentTitle} from "../../services/DocumentTitle";

const PREFIX = 'Contact';

const classes = {
    body: `${PREFIX}-body`,
    typography: `${PREFIX}-typography`,
    gridContainer: `${PREFIX}-gridContainer`,
    gridItem: `${PREFIX}-gridItem`,
    mailPhone: `${PREFIX}-mailPhone`,
    container: `${PREFIX}-container`
};

const Root = styled('div')(({theme}) => ({
    [`&.${classes.body}`]: {
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up('md')]: {
            minHeight: "calc(100vh - 260px - 95px)" //substract height of footer and header
        },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },

    [`& .${classes.typography}`]: {
        padding: "5px",
    },

    [`& .${classes.gridContainer}`]: {
        justifyContent: "center"
    },

    [`& .${classes.gridItem}`]: {
        [theme.breakpoints.up('md')]: {
            maxWidth: "45%",
        },
        padding: "10px",
    },

    [`& .${classes.mailPhone}`]: {
        paddingLeft: "5px"
    },

    [`& .${classes.container}`]: {
        display: "flex",
        height: "100%",
        alignContent: "space-between"
    }
}));

export default function Contact() {

    useDocumentTitle("Kontakt", "Gerne sind wir telefonisch oder per Email für Sie erreichbar.");

    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'));

    return (
        <Root className={classes.body}>
            <Card elevation={0} style={isSmallScreen ? {width: "95%", margin: "50px"} : {width: "70%", margin: "50px"}}>
                <CardContent>
                    <Typography variant="h1" align="center" style={{paddingBottom: "15px", fontSize: 35}}>Kontakt</Typography>
                    <Grid container className={classes.gridContainer}>
                        <Grid item xs={12} md={6} className={classes.gridItem}>
                            <Card elevation={2}>
                                <CardContent style={{height: "100%"}}>
                                    <Grid container className={classes.container}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" className={classes.typography}>Beratung</Typography>
                                            <Typography variant="body1" className={classes.typography}>
                                                Sind Sie interessiert an einem unserer Produkte oder unseren
                                                Dienstleistungen?
                                            </Typography>
                                            <Typography variant="body1" className={classes.typography}>
                                                Schreiben Sie uns eine Mail oder rufen Sie uns gleich an. Wir freuen
                                                uns, Sie kennenzulernen und vereinbaren gerne einen unverbindlichen
                                                Beratungstermin mit Ihnen.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className={classes.mailPhone}>
                                            <Typography variant="body1">
                                                <a href="mailto:info@secacon.com" style={{color: "#000000"}}>info@secacon.com</a>
                                                <br/>
                                                <a href="tel: +41 61 462 16 00" style={{color: "#000000", textDecoration: "none"}}>+41 61 462 16 00</a>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.gridItem}>
                            <Card elevation={2} style={{height: "100%"}}>
                                <CardContent style={{height: "calc(100% - 40px)"}}>
                                    <Grid container className={classes.container}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" className={classes.typography}>
                                                Support
                                            </Typography>
                                            <Typography variant="body1" className={classes.typography}>
                                                Sie sind bereits Kunde und haben eine Frage oder benötigen
                                                Unterstützung?
                                            </Typography>
                                            <Typography variant="body1" className={classes.typography}>
                                                Schildern Sie uns Ihr Anliegen per Mail oder Telefon und wir setzen
                                                alles daran, dass wir Ihr Problem schnellstmöglich beheben.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className={classes.mailPhone}>
                                            <Typography variant="body1">
                                                <a href="mailto:support@secacon.com" style={{color: "#000000"}}>support@secacon.com</a>
                                                <br/>
                                                <a href="tel: +41 61 462 16 05" style={{color: "#000000", textDecoration: "none"}}>+41 61 462 16 05</a>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Root>
    );
}