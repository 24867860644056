import React from "react";
import {styled} from '@mui/material/styles';
import {isIE} from 'react-device-detect';

const PREFIX = 'IEHeaderSpace';

const classes = {
    space: `${PREFIX}-space`
};

const Root = styled('div')(({theme}) => ({
    [`& .${classes.space}`]: {
        height: "95px",
        [theme.breakpoints.down('lg')]: {
            height: "70px",
        },
        width: "100%",
        maxWidth: "100%",
    }
}));

export default function IEHeaderSpace() {
    return <Root className={isIE ? classes.space : ''}/>;
}