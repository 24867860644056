import React, {FunctionComponent} from "react";
import {styled} from '@mui/material/styles';
import {CustomCardModel} from "../../services/Models";
import {Button, ButtonBase, Card, CardActions, CardContent, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import {isIE} from "react-device-detect";

const PREFIX = 'HomePageCards';

const classes = {
    customCard: `${PREFIX}-customCard`,
    overlay: `${PREFIX}-overlay`,
    overlayHover: `${PREFIX}-overlayHover`,
    cardTitle: `${PREFIX}-cardTitle`,
    cardTitleHover: `${PREFIX}-cardTitleHover`,
    cardImg: `${PREFIX}-cardImg`,
    cardImgHover: `${PREFIX}-cardImgHover`,
    imgFill: `${PREFIX}-imgFill`,
    imgFillHover: `${PREFIX}-imgFillHover`,
    mappedCard: `${PREFIX}-mappedCard`,
    titleCard: `${PREFIX}-titleCard`,
};

export const CARD_WIDTH = 320;
const CARD_HEIGHT = 240;

const Root = styled("div")(({theme}) => ({
    display: "flex",
    justifyContent: "center",

    [`& .${classes.customCard}`]: {
        width: CARD_WIDTH - 32, // minus padding
        height: CARD_HEIGHT - 32,
        position: "relative",
        borderRadius: "4px"
    },

    [`& .${classes.overlay}`]: {
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        background: "linear-gradient(180deg, rgba(50,77,95,0.68) 25%, rgba(50,77,95,0.5) 50%)",
        position: "absolute",
        top: 0, left: 0,
        zIndex: 98,
        clipPath: "polygon(0 0, 100% 0, 80% 100%, 0% 100%)",
        webkitClipPath: "polygon(0 0, 100% 0, 80% 100%, 0% 100%)",
        transition: "0.8s",
    },

    [`& .${classes.overlayHover}`]: {
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        background: "linear-gradient(180deg, rgba(50,77,95,0.7) 25%, rgba(50,77,95,0.5) 50%)",
        position: "absolute",
        top: 0, left: 0,
        zIndex: 98,
        clipPath: "polygon(0 0, 0% 0, 0% 100%, 0% 100%)",
        webkitClipPath: "polygon(0 0, 0% 0, 0% 100%, 0% 100%)",
        transition: "0.6s",
    },

    [`& .${classes.cardTitle}`]: {
        color: theme.palette.secondary.contrastText,
        fontSize: "28.5px",
        zIndex: 99,
        position: "relative",
        transition: "1s",
    },

    [`& .${classes.cardTitleHover}`]: {
        color: theme.palette.secondary.main,
        fontSize: "28.5px",
        zIndex: 99,
        position: "relative",
        transition: "1s",
    },

    [`& .${classes.cardImg}`]: {
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        clipPath: "polygon(0 0, 100% 0, 80% 100%, 0% 100%)",
        webkitClipPath: "polygon(0 0, 100% 0, 80% 100%, 0% 100%)",
        transition: "0.8s",
        position: "absolute",
        zIndex: 90,
        top: 0,
        left: 0,
        opacity: "1"
    },

    [`& .${classes.cardImgHover}`]: {
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        clipPath: "polygon(0 0, 0% 0, 0% 100%, 0% 100%)",
        webkitClipPath: "polygon(0 0, 0% 0, 0% 100%, 0% 100%)",
        transition: "0.6s",
        position: "absolute",
        zIndex: 90,
        top: 0,
        left: 0,
        opacity: "0",
    },

    [`& .${classes.imgFill}`]: {
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        zIndex: 97,
        bottom: 0, right: 0,
        transition: "0.8s",
        position: "absolute",
        backgroundColor: "#ffffff",
        clipPath: "polygon(100% 0, 80% 100%, 100% 100%)",
        webkitClipPath: "polygon(100% 0, 80% 100%, 100% 100%)",
    },

    [`& .${classes.imgFillHover}`]: {
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        zIndex: 97,
        bottom: 0, right: 0,
        transition: "0.6s",
        position: "absolute",
        backgroundColor: "#ffffff",
        clipPath: "polygon(100% 0, 100% 100%, 100% 100%)",
        webkitClipPath: "polygon(100% 0, 100% 100%, 100% 100%)",
    },

    [`& .${classes.mappedCard}`]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: 8,
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
    },

    [`& .${classes.titleCard}`]: {
        color: theme.palette.secondary.contrastText,
        background: "linear-gradient(90deg, rgba(73,165,255,1) 0%, rgba(48,110,170,1) 100%)",
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        transition: "0.8s",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
}));

const CustomCard: FunctionComponent<CustomCardModel> = props => {

    const [classname, setClassname] = React.useState(false);
    const [elevation, setElevation] = React.useState(6);

    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

    function changeClassname() {
        setClassname(true);
        setElevation(2);
    }

    function resetClassname() {
        setClassname(false);
        setElevation(6);
    }

    function handleClick() {
        window.scrollTo(0, 0)
    }

    function toggleText() {
        setClassname(prevState => !prevState);
    }

    if (!isMobileScreen) {
        // Desktop Version
        return (
            <Root>
                <Card elevation={elevation} style={{width: CARD_WIDTH, height: CARD_HEIGHT}}>
                    <ButtonBase component={Link} to={props.url} onClick={handleClick}>
                        <CardContent className={classes.customCard} onMouseEnter={changeClassname} onMouseLeave={resetClassname}>
                            {isIE ? '' : <div className={classname ? classes.overlayHover : classes.overlay}/>}
                            <Typography variant="h6" className={classname ? classes.cardTitleHover : classes.cardTitle}><strong>{props.title}</strong></Typography>
                            <img src={props.image} className={classname ? classes.cardImgHover : classes.cardImg} alt="Img not loaded"/>
                            {isIE ? '' : <div className={classname ? classes.imgFillHover : classes.imgFill}/>}
                            <Typography variant="body2">
                                {props.content}
                            </Typography>
                        </CardContent>
                    </ButtonBase>
                </Card>
            </Root>
        );
    } else {
        // Mobile Version
        return (
            <Root>
                <Card elevation={elevation} style={{maxHeight: CARD_HEIGHT}}>
                    <ButtonBase onClick={toggleText} disableRipple>
                        <CardContent className={classes.customCard}>
                            <div>
                                <div className={classname ? classes.overlayHover : classes.overlay}/>
                                <Typography variant="h6" className={classes.cardTitle} style={{textAlign: "left"}}><strong>{props.title}</strong></Typography>
                                <img src={props.image} className={classname ? classes.cardImgHover : classes.cardImg} alt="Img not loaded"/>
                                <div className={classname ? classes.imgFillHover : classes.imgFill}/>
                                <Typography variant="body2" style={{textAlign: "left"}}>{props.content}</Typography>
                            </div>
                        </CardContent>
                    </ButtonBase>
                    <CardActions style={{maxHeight: 0,}}>
                        <Button variant="text" style={{position: "relative", top: -30, right: -195}} onClick={handleClick}>
                            <Link to={props.url} style={{textDecoration: "none", color: "#4aa6ff"}}>Mehr Infos</Link>
                        </Button>
                    </CardActions>
                </Card>
            </Root>
        )
    }
}

export function MappedCards(props: { content: CustomCardModel[] }) {

    const content = props.content;

    // Root cannot be used to supply css
    const Mapped = content.map((content: CustomCardModel, key: number) => {
        return (
            <Grid item md={6} lg={4} key={key} style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                padding: 8
            }}>
                <CustomCard title={content.title} content={content.content} image={content.image} url={content.url}/>
            </Grid>
        )
    });
    return <>{Mapped}</>
}

export function TitleCard(props: { title: string }) {

    const [elevation, setElevation] = React.useState(6);

    function decreaseElevation() {
        setElevation(2);
    }

    function increaseElevation() {
        setElevation(6);
    }

    return (
        <Grid item md={6} lg={4} style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            padding: 8
        }}>
            <Root style={{width: CARD_WIDTH, height: CARD_HEIGHT}}>
                <Card elevation={elevation} className={classes.titleCard} onMouseEnter={decreaseElevation} onMouseLeave={increaseElevation}>
                    <CardContent><Typography variant="h6" fontSize={28}> <strong>{props.title}</strong> </Typography></CardContent>
                </Card>
            </Root>
        </Grid>
    )
}