import React from "react";
import {styled} from '@mui/material/styles';
import {Card, CardContent, Icon, List, ListItem, Typography} from "@mui/material";
import {DonutLarge} from "@mui/icons-material";

const PREFIX = 'SecurityConsultingCard';

const classes = {
    card: `${PREFIX}-card`,
    container: `${PREFIX}-container`
};

const Root = styled('div')(({theme}) => ({
    [`& .${classes.card}`]: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },

    [`&.${classes.container}`]: {
        display: "flex",
        justifyContent: "center",
        width: 366,
        [theme.breakpoints.down('lg')]: {
            width: 280
        },
        [theme.breakpoints.down('md')]: {
            width: "80%",
        }
    }
}));

const ListItems: string[] = [
    "Netzwerk-Übergänge",
    "(Web-) Server",
    "Virenschutz",
    "E-Mail-Schutz",
    "Schutz gespeicherter Daten",
    "Schutz der Kommunikation",
    "Public-Key-Infrastruktur (PKI)",
    "Digitale Signaturen",
]

export default function SecurityConsultingCard() {

    return (
        <Root className={classes.container}>
            <Card className={classes.card} elevation={3}>
                <CardContent style={{backgroundColor: "#ece8e3"}}>
                    <Typography variant="h6" style={{padding: "5px 5px 0 5px"}}>
                        <strong>
                            Beratung zu spezifischen Themen
                        </strong>
                    </Typography>
                    <Typography variant="body1" style={{padding: "5px"}}>
                        Gerne beraten wir Sie in einem persönlichen Gespräch zu verschiedensten IT
                        Security Themenbereichen. Zögern Sie nicht, mit uns Kontakt aufzutreten.
                    </Typography>
                </CardContent>
                <div>
                    <CardContent>
                        <List>
                            {ListItems.map((item: string, key) => {
                                return (
                                    <ListItem key={key}>
                                        <Icon style={{
                                            marginRight: "7px",
                                            display: "flex",
                                            alignSelf: "flex-start"
                                        }}>
                                            <DonutLarge/>
                                        </Icon>
                                        <Typography variant="body2">
                                            {item}
                                        </Typography>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </CardContent>
                </div>
                <CardContent style={{
                    backgroundColor: "#ece8e3",
                    width: "100%",
                    marginTop: "auto"
                }}>
                    <Typography variant="body1" style={{padding: "5px"}}>
                        Sollten Sie noch weitere Fragen zu nicht aufgeführten Themen haben, freuen
                        wir uns ebenfalls auf Ihre Anfrage.
                    </Typography>
                </CardContent>
            </Card>
        </Root>
    );
}