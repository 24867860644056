import React from "react";
import {styled} from '@mui/material/styles';
import {Card, CardActions, CardContent, CardHeader, Divider, Grid, Icon, List, ListItem, Typography} from "@mui/material";

import {DonutLarge} from "@mui/icons-material";

import {EngineeringCardModel} from "../../services/Models";

const PREFIX = 'EngineeringCard';

const classes = {
    card: `${PREFIX}-card`,
    icon: `${PREFIX}-icon`,
    iconPos: `${PREFIX}-iconPos`,
    divider: `${PREFIX}-divider`,
    dividerContainer: `${PREFIX}-dividerContainer`,
    typography: `${PREFIX}-typography`
};

const StyledGrid = styled(Grid)(({theme}) => ({
    [`& .${classes.card}`]: {
        borderRadius: "0px",
        background: "linear-gradient(90deg, rgba(240,240,240,0.17) 0%, rgba(255,240,224,0.25) 100%)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('lg')]: {
            maxWidth: "70%",
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: "100%",
        },
    },

    [`& .${classes.icon}`]: {
        height: 55,
        width: 55,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    [`& .${classes.iconPos}`]: {
        display: "flex",
        justifyContent: "center"
    },

    [`& .${classes.divider}`]: {
        padding: "2px",
        width: "80%",
        alignSelf: "center"
    },

    [`& .${classes.dividerContainer}`]: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center"
    },

    [`& .${classes.typography}`]: {
        [theme.breakpoints.down('xl')]: {
            height: "unset",
        },
    }
}));

export default function EngineeringCard(props: EngineeringCardModel) {

    const [list, setList] = React.useState(false);

    React.useEffect(() => {
        if (props.list) {
            setList(props.list)
        }
    }, [props.list])


    return (
        <StyledGrid item xs={12} lg={4} style={{display: "flex", justifyContent: "center"}}>
            <Card className={classes.card} elevation={5}>
                <CardHeader
                    title={props.title}
                    align="center"
                    style={{backgroundColor: "#efeae5"}}
                />
                <CardContent>
                    <Typography variant="body1" className={classes.typography}>
                        {props.description}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    <Grid container>
                        <Grid item xs={5} className={classes.dividerContainer}>
                            <Divider className={classes.divider}/>
                        </Grid>

                        <Grid item xs={2} className={classes.iconPos}>
                            <Icon
                                className={classes.icon}
                            >
                                {props.icon}
                            </Icon>
                        </Grid>

                        <Grid item xs={5} className={classes.dividerContainer}>
                            <Divider className={classes.divider}/>
                        </Grid>
                    </Grid>
                </CardActions>
                <CardContent>
                    {list ?
                        <List>
                            {Array.isArray(props.info) ?
                                props.info.map((item) =>
                                    <ListItem key={item}>
                                        <Icon style={{marginRight: "7px", display: "flex", alignSelf: "flex-start"}}>
                                            <DonutLarge/>
                                        </Icon>
                                        <Typography variant="body2">
                                            {item}
                                        </Typography>
                                    </ListItem>
                                )
                                :
                                "Error: Couldn't load info."
                            }
                        </List>
                        :
                        <Typography variant="body2">
                            {props.info}
                        </Typography>
                    }
                </CardContent>

                <CardContent className={classes.dividerContainer} style={{marginTop: "auto"}}>
                    <Divider className={classes.divider} style={{width: "93.5%"}}/>
                </CardContent>

                <CardContent>
                    <Typography variant="body1">
                        <strong>
                            Referenz:
                        </strong>
                    </Typography>
                    <Typography variant="body1" className={classes.typography}>
                        {props.reference}
                    </Typography>
                </CardContent>

                <CardContent style={{
                    backgroundColor: "#ece8e3",
                    height: "15px",
                    width: "100%",
                    padding: "0",
                }}/>
            </Card>
        </StyledGrid>
    );
}
