import React from "react";
import {styled} from '@mui/material/styles';
import {Card, CardContent, CardHeader, Container, Divider, Grid, ImageList, ImageListItem, ImageListItemBar, Typography, useMediaQuery, useTheme} from "@mui/material";
import ImageViewer from "../../services/ImageViewer";
import PageHeader from "../elements/PageHeader";
import {isIE} from "react-device-detect";

import Contract from "../../resources/secasign-cli/undraw_Terms_re_6ak4.png";
import Files from "../../resources/secasign-cli/undraw_my_files_swob.png";
import Security from "../../resources/secasign-cli/undraw_Security_on_ff2u.png";
import Console from "../../resources/secasign-cli/Bild_pdfsig_Linux2.png";
import Adobe from "../../resources/secasign-cli/Bild_AdbobeReader_Windows.png";
import Diagram from "../../resources/secasign-cli/Secasign-CLI.png";
import {useDocumentTitle} from "../../services/DocumentTitle";

const PREFIX = 'SecasignCli';

const classes = {
    body: `${PREFIX}-body`,
    paragraph: `${PREFIX}-paragraph`,
    card: `${PREFIX}-card`,
    logo: `${PREFIX}-logo`,
    logoPos: `${PREFIX}-logoPos`,
    titleBar: `${PREFIX}-titleBar`
};

const Root = styled('div')(({theme}) => ({
    [`& .${classes.body}`]: {
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up('md')]: {
            minHeight: "calc(100vh - 221px - 95px)" //substract height of footer and header
        }
    },

    [`& .${classes.paragraph}`]: {
        padding: "15px 0 15px 0",
    },

    [`& .${classes.card}`]: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgba(240,240,240,0.17)",
    },

    [`& .${classes.logo}`]: {
        maxWidth: "100%",
        maxHeight: "100%",
    },

    [`& .${classes.logoPos}`]: {
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignContent: "center"
    },

    [`& .${classes.titleBar}`]: {
        backgroundColor: '#d8e0ef',
        '& .MuiImageListItemBar-titleWrap': {
            color: "#000009"
        },
    }
}));

const cardContent = [
    {
        title: "Signatur",
        logo: `${Contract}`,
        text: "Schliessen Sie Verträge digital ab. Mit Secasign-CLI können Sie Verträge bindend unterschreiben. Die digitale Unterschrift " +
            "(nach ZertIES) ist der handschriftlichen Unterschrift gleichgestellt. Sie hat sogar den Vorzug, das nachträgliche Änderungen" +
            " am Dokument auch mit grossem Aufwand nicht möglich sind. Nebst der qualifizierten Unterschrift unterstützt Secasign-CLI auch" +
            " Firmensiegel und andere Unterschriftsarten.",
    },

    {
        title: "Schutz",
        logo: `${Security}`,
        text: "Verhindern Sie durch die Signatur mit Secasign-CLI, dass Ihre Dokumente gefälscht oder manipuliert werden können. Secasign-CLI lässt " +
            "Sie und Ihre Kunden jederzeit den Ursprung eines Dokumentes prüfen, sei es ein PDF, eine XML-Datei oder eine beliebige andere Datei.",
    },

    {
        title: "Archivieren",
        logo: `${Files}`,
        text: "Zusätzlich zur gewonnenen Rechtssicherheit können Sie mit Secasign-CLI ihren nächsten Schritt zur Digitalisierung und zum ‚paperless " +
            "office‘ gehen: verzichten Sie künftig auf viel zu grosse Archivräume archivieren Sie Ihre Dokumente digital. Für den bestmöglichen" +
            " Einsatz von Secasign-CLI gibt es Secasign-CLI mit Archiv-Erweiterung. Wir beraten Sie gerne."
    },
]

export default function SecasignCli() {

    useDocumentTitle("Secasign-CLI", "Sicheres signieren Ihrer elektronischen Dokumente.");

    const theme = useTheme();

    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

    const isMediumScreen = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Root>
            <PageHeader whiteTitle="Secasign-" blueTitle="CLI" subtitle="Sicheres signieren Ihrer elektronischen Dokumente"/>
            <div className={classes.body}>
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid item xs={12}>
                            <div className={classes.paragraph}>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <Grid container spacing={isMobileScreen ? 3 : 4}
                                          style={{maxWidth: isMobileScreen ? "98%" : "100%"}}>
                                        {cardContent.map((content, key) => {
                                            return (
                                                <Grid item xs={12} lg={4} key={key} style={isMediumScreen ? {} : {height: "100%"}}>
                                                    <Container maxWidth={isMediumScreen ? "md" : false} style={isMobileScreen ? {width: "100%"} : isMediumScreen ? {width: "80%", padding: 0} : {height: "100%", width: "100%", padding: 0}}>
                                                        <Card className={classes.card} elevation={3}>
                                                            <CardHeader
                                                                title={content.title}
                                                                align="center"
                                                                style={{backgroundColor: "#efeae5"}}
                                                            />
                                                            <div>
                                                                <CardContent className={classes.logoPos}>
                                                                    <img src={content.logo}
                                                                         alt="logo"
                                                                         className={classes.logo}/>
                                                                </CardContent>
                                                                <CardContent>
                                                                    <Typography>{content.text}</Typography>
                                                                </CardContent>
                                                            </div>
                                                            <CardContent style={{
                                                                backgroundColor: "#ece8e3",
                                                                height: "15px",
                                                                width: "100%",
                                                                padding: "0",
                                                                marginTop: "auto"
                                                            }}/>
                                                        </Card>
                                                    </Container>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider style={{margin: "0 40% 0 40%", padding: "2px"}}/>
                        </Grid>

                        <Grid item xs={12}>
                            <div className={classes.paragraph}>
                                <Typography variant="h6">
                                    <strong>
                                        Funktionsweise
                                    </strong>
                                </Typography>
                                <Typography variant="body1">
                                    Secasign-CLI verwendet entweder einen lokalen Token, auf welchem ein digitales GeBüV-Zertifikat, ein fortgeschrittenes
                                    oder ein qualifiziertes Zertifikat gespeichert ist. Alternativ kann aber der Swisscom Signatur-Service eingesetzt
                                    werden – damit kann ganz auf einen lokalen Token verzichtet werden. Secasign-CLI sorgt dafür, dass die Signatur richtig
                                    erstellt und anschliessend in das Dokument integriert, resp an das Dokument angehängt wird.
                                    <br/><br/>
                                    Mit Secasign-CLI können Sie nicht nur einzelne Dokumente signieren, sondern mithilfe der Batchfunktion auch mehrere Dokumente
                                    gleichzeitig signieren.
                                    <br/><br/>
                                    Verwenden können Sie Secasign-CLI auf Windows und auf Linux Systemen.
                                    <br/><br/>

                                </Typography>

                                <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 30}}>
                                    <Card elevation={4} style={{maxWidth: "650px"}}>
                                        <CardContent style={{padding: "10px"}}>
                                            <div style={isIE && isMediumScreen ? {
                                                margin: "auto",
                                                left: 0,
                                                right: 0,
                                                width: 600
                                            } : {display: "flex", justifyContent: "center"}}>
                                                <ImageViewer image={Diagram} alt="Diagramm"/>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        </Grid>

                        <Grid container spacing={2} item xs={12} style={{display: "flex", justifyContent: "center"}}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6">
                                    <strong>
                                        Variationen
                                    </strong>
                                </Typography>
                                <Typography variant="body1">
                                    Erhältlich ist Secasign-CLI einerseits als Kommandozeilenapplikation und andererseits
                                    als Software Development Kit (SDK). Sie können Secasign-CLI also unabhängig betreiben
                                    oder aber es in Ihr eigenes Produkt integrieren.<br/><br/>
                                </Typography>

                                <Typography variant="h6">
                                    <strong>
                                        Evaluation Kit
                                    </strong>
                                </Typography>
                                <Typography variant="body1">
                                    Das Secasign-CLI Evaluation Kit ermöglicht zudem die Generierung von Signaturen und Zeitstempeln,
                                    die von der Struktur her allen Anforderungen an fortgeschrittene und qualifizierte Unterschriften genügen.
                                    Weil hierbei aber mit einem selbst signierten Zertifikat gearbeitet wird, ist die Vertrauenswürdigkeit eingeschränkt.<br/>
                                    Zögern Sie nicht, mit uns Kontakt aufzunehmen, wenn Sie sich für das Secasign-CLI Evaluation Kit interessieren.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ImageList component={Grid} rowHeight={isMobileScreen ? 150 : 200} cols={1} style={{marginBottom: "10px"}}>
                                    <ImageListItem>
                                        <div style={{display: "flex", justifyContent: "center", maxHeight: 195, overflow: "hidden"}}>
                                            <ImageViewer alt="Adobe-Signatur" image={Adobe}/>
                                        </div>
                                        <ImageListItemBar
                                            title="Adobe-Signatur"
                                            position="top"
                                            className={classes.titleBar}
                                        />
                                    </ImageListItem>
                                    <ImageListItem>
                                        <div style={{display: "flex", justifyContent: "center", maxHeight: 195, overflow: "hidden"}}>
                                            <ImageViewer alt="Console-Signature" image={Console}/>
                                        </div>
                                        <ImageListItemBar
                                            title="PDF Signatur"
                                            position="top"
                                            className={classes.titleBar}
                                        />
                                    </ImageListItem>
                                </ImageList>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Root>
    );
}