import React from "react";
import {styled, useTheme} from '@mui/material/styles';
import {Button, Card, CardContent, Container, Divider, Grid, Typography, useMediaQuery} from "@mui/material";
import ImageViewer from "../../services/ImageViewer";
import PageHeader from "../elements/PageHeader";
import {Link} from "react-router-dom";
import {isIE} from "react-device-detect";

import {TileProps} from "../../services/Models";

import Archivkonfiguration from "../../resources/secasign/Archivkonfiguration.png";
import Archivsuche from "../../resources/secasign/Archivsuche.png";
import Dashboard from "../../resources/secasign/Dashboard.png";
import Export from "../../resources/secasign/Export.png";
import Automatisierung from "../../resources/secasign/Automatisierung.png";
import Parametrisierung from "../../resources/secasign/Parametrisierung.png";
import RestApi from "../../resources/secasign/RestApi.png";
import Signieren from "../../resources/secasign/Signieren.png";
import Diagram from "../../resources/secasign/Diagram.png";
import {useDocumentTitle} from "../../services/DocumentTitle";

const PREFIX = 'Secasign';

const classes = {
    body: `${PREFIX}-body`,
    imageResize: `${PREFIX}-imageResize`,
    imageResizeIE: `${PREFIX}-imageResizeIE`,
    card: `${PREFIX}-card`,
    diagramContainer: `${PREFIX}-diagramContainer`,
    diagramIE: `${PREFIX}-diagramIE`,
    diagramIENotLG: `${PREFIX}-diagramIENotLG`
};

const Root = styled('div')(({theme}) => ({
    [`& .${classes.body}`]: {
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up('md')]: {
            minHeight: "calc(100vh - 221px - 95px)" //substract height of footer and header
        }
    },

    [`& .${classes.imageResize}`]: {
        [theme.breakpoints.down('lg')]: {
            maxWidth: "60%"
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: "80%"
        },
        display: "flex",
        flexDirection: "column",
        position: "relative",
        right: 0, left: 0,
        margin: "auto",
        maxHeight: "100%",
        maxWidth: "100%",
    },

    [`& .${classes.imageResizeIE}`]: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        right: 0, left: 0,
        margin: 0,
        maxHeight: "100%",
        width: 200,
        height: 150,
        [theme.breakpoints.down('xl')]: {
            width: 140,
            height: 105,
        },
        [theme.breakpoints.down('lg')]: {
            width: 500,
            height: 375,
        },
    },

    [`& .${classes.card}`]: {
        borderRadius: 0,
        padding: "16px",
        width: "100%",
        height: "100%",
    },

    [`& .${classes.diagramContainer}`]: {
        marginBottom: "24px",
        borderRadius: 0
    },

    [`& .${classes.diagramIE}`]: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },

    [`& .${classes.diagramIENotLG}`]: {
        left: 0, right: 0,
        margin: "auto",
        position: "relative",
        [theme.breakpoints.down('xl')]: {
            width: 750,
        },
        [theme.breakpoints.down('lg')]: {
            width: 350,
        },
    }
}));


const OptionInfo = [
    {
        icon: `${Dashboard}`,
        title: "Dashboard",
        text: "Alle wichtigen Informationen und der aktuelle Status von Secasign wird im Dashboard zusammengefasst. Navigieren Sie von hier zu allen Unterbereichen um mehr Details zu erhalten und Ihre Daten zu verwalten.",
    },
    {
        icon: `${Automatisierung}`,
        title: "Automatisierung",
        text: <>Sei es via E-Mail, Netzlaufwerken oder über eine REST/SOAP-Schnittstelle, Secasign bietet
            Ihnen verschiedenste Auto&shy;ma&shy;ti&shy;sie&shy;rungs&shy;mö&shy;glich&shy;keiten.
            So können Dokumente eingeliefert, signiert und weitergeleitet werden.</>
    },
    {
        icon: `${Archivsuche}`,
        title: "Archivsuche",
        text: "Die Volltextsuche von Secasign ermöglicht es Ihnen bei Bedarf jedes archivierte Dokument innert kürzester" +
            " Zeit zu suchen. So vermeiden Sie mühsames Wühlen in Ordnern im kalten Kellerraum."
    },
    {
        icon: `${Archivkonfiguration}`,
        title: "Archivparameter",
        text: "Archive können mit frei konfigurierbaren und durchsuchbaren Attributen, wie beispielsweise einer Rechnungsnummer, konfiguriert werden."
    },
    {
        icon: `${Parametrisierung}`,
        title: "Parametrisierung",
        text: "Erfassen und verwalten Sie Ihre Archive und deren Verarbeitung. So können Werte aus dem Dateinamen auf Archivattribute gemappt werden."
    },
    {
        icon: `${Export}`,
        title: "Export",
        text: "Falls nötig, können Sie ihre Dokumente schnell und einfach auf ein beliebiges Netzlaufwerk exportieren."
    },
    {
        icon: `${Signieren}`,
        title: "Signieren",
        text: "Über einen Wizard können Dokumente firmenbasiert oder persönlich signiert werden."
    },
    {
        icon: `${RestApi}`,
        title: "REST-API",
        text: "Secasign kann über eine REST-Schnittstelle gesteuert und in Ihr System integriert werden."
    },
]

function TileLayout(props: TileProps) {

    const imageLayout =
        <Grid item xs={12} md={4} style={{paddingTop: 13, maxHeight: "100%"}}>
            <Root className={isIE ? classes.imageResizeIE : classes.imageResize}>
                <ImageViewer alt={props.title} image={props.icon} border={true}/>
            </Root>
        </Grid>

    const textLayout =
        <Grid item xs={12} md={8}>
            <Typography variant="h6" style={{padding: "5px 15px 5px 15px"}}>{props.title}</Typography>
            <Typography variant="body1" style={{padding: "0px 15px 15px 15px"}}>{props.text}</Typography>
        </Grid>

    return (
        <Card elevation={4} className={classes.card}>
            <Grid container>
                {imageLayout}
                {textLayout}
            </Grid>
        </Card>
    )
}

function Tiles(props: { options: TileProps[] }) {

    const options = props.options;

    const mapped = options.map((option: TileProps, key: number) =>
        <Grid item xs={12} md={6} key={key} container>
            <TileLayout icon={option.icon} title={option.title} text={option.text} even={key % 2 === 0}/>
        </Grid>
    )

    return (
        <>{mapped}</>
    );
}

export default function Secasign() {

    useDocumentTitle("Secasign", "Mit der Secasign bieten wir Ihnen ein erstklassiges Tool zur Archivierung all Ihrer Dokumente. Secasign vereint die digitale Dokumentensignierung von Secasign-CLI und die Möglichkeit Ihre Dokumente geordnet zu archivieren und wieder finden zu können.")

    const theme = useTheme();

    const isMediumScreen = useMediaQuery(theme.breakpoints.down('xl'));

    return (
        <Root>
            <PageHeader whiteTitle="Seca" blueTitle="sign" subtitle="Sichere Archivierung Ihrer Dateien"/>
            <div className={classes.body}>
                <Container maxWidth="lg">
                    <Grid container spacing={0}>
                        <Grid item xs={12} display="flex" alignItems="center" flexDirection="column">
                            <div>
                                <Typography variant="subtitle1"
                                            style={{maxWidth: "90%", textAlign: "center", margin: "20px"}}>
                                    <strong>
                                        Mit Secasign bieten wir Ihnen ein erstklassiges Tool zur Archivierung
                                        all Ihrer Dokumente. Secasign vereint die digitale Dokumentensignierung
                                        von Secasign-CLI und die Möglichkeit Ihre Dokumente geordnet zu archivieren und
                                        wieder finden zu können.
                                    </strong>
                                </Typography>
                            </div>

                            <Card elevation={4} className={classes.diagramContainer}>
                                <CardContent style={{padding: "10px", maxWidth: 650}}>
                                    <div className={isIE ? (isMediumScreen ? classes.diagramIENotLG : classes.diagramIE) : ''} style={isIE ? {} : {display: "flex", justifyContent: "center"}}>
                                        <ImageViewer alt="Secasign Diagram" image={Diagram}/>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={5}>
                                <Tiles options={OptionInfo}/>
                            </Grid>
                        </Grid>

                        <Grid container item spacing={2} xs={12} style={{marginTop: "50px"}}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    <strong>
                                        Betrieb
                                    </strong>
                                </Typography>
                                <Typography variant="body1">
                                    Für den Betrieb von Secasign existieren mehrere Möglichkeiten:
                                    <ul>
                                        <li>Als Service auf einem von Ihnen betreuten Windows Server 2019 oder höher.</li>
                                        <li>Als linuxbasierte, auf VMWare ESXi oder Microsoft HyperV virtualisierte, Appliance.</li>
                                        <li>Als linuxbasierten Docker-Container.</li>
                                        <li>Auf einem durch uns gestellten Kleinrechner (Stand-Alone).</li>
                                    </ul>
                                    Wenn Sie möchten, können Sie Secasign alternativ bei uns
                                    hosten. Wir holen dann die Dokumente von Ihrem System ab, signieren und archivieren
                                    sie bei uns und liefern Sie Ihnen im Anschluss signiert zurück. Sie erhalten ausserdem
                                    einen Zugang zu ihrem Archiv, in welchem Sie die Dokumente jederzeit einsehen
                                    können.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} style={{padding: "1px", margin: "3%"}}>
                                <Divider style={{padding: "1px"}}/>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    <strong>
                                        Preise
                                    </strong>
                                </Typography>
                                <Typography variant="body1">
                                    Secasign kann in zwei Varianten lizenziert werden:
                                    <ul>
                                        <li>Als reine Signierlösung ohne Archivierung (Die signierten Dokumente verbleiben dann nur einen Tag im Archiv und werden danach automatisiert gelöscht). Einsatzgebiet: Es existiert bereits ein DMS/Archiv und Secasign wird nur für die Signierung verwendet: <b>Preis 450 CHF/Jahr</b></li>
                                        <li>Als Signierlösung mit Archiv, um die signierten Dokumente auch dauerhaft archivieren zu können. Einsatzgebiet: Secasign wird sowohl für die Signierung als auch die Archivierung als Archiv verwendet: <b>Preis 1750 CHF/Jahr</b></li>
                                    </ul>
                                    Zudem resultieren folgende Preise pro Dokumentensignatur:
                                    <ul>
                                        <li>Cloudbasierte Signierung
                                            <ul>
                                                <li>Firmenbasiert: Ab 0.20 CHF/Signierung (Volumenabhängig)</li>
                                                <li>Persönlich: Ab 2.40 CHF/Signierung (Volumenabhängig)</li>
                                            </ul>
                                        </li>
                                        <li>Firmenbasierte Signierung via USB PKCS11-Token/HSM: Auf Anfrage</li>
                                    </ul>
                                  </Typography>
                            </Grid>

                            <Grid item xs={12} style={{padding: "1px", margin: "3%"}}>
                                <Divider style={{padding: "1px"}}/>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    <strong>
                                        Demo
                                    </strong>
                                </Typography>
                                <Typography variant="body1">
                                    Schreiben Sie uns ein Mail oder rufen Sie uns an. Wir freuen uns, Sie
                                    kennenzulernen und vereinbaren gerne einen unverbindlichen Beratungstermin mit
                                    Ihnen. Dabei erhalten Sie die Möglichkeit, Secasign live zu testen und
                                    sich persönlich davon überzeugen zu lassen.<br/>
                                    <Button component={Link}
                                            to="/contact"
                                            variant="outlined"
                                            style={{
                                                textDecoration: "none",
                                                color: "#000000",
                                                fontSize: 18,
                                                float: "right",
                                                marginTop: "5px"
                                            }}
                                            onClick={() => window.scrollTo(0, 0)}
                                    >
                                        Kontakt
                                    </Button>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Root>
    )
}