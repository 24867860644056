import React, {useState} from "react";
import {styled} from '@mui/material/styles';
import {Typography, useMediaQuery, useTheme} from "@mui/material";

import Office from "../../resources/Office.jpg";

import {PageHeaderModel} from "../../services/Models";

const PREFIX = 'PageHeader';

const classes = {
    imageContainer: `${PREFIX}-imageContainer`,
    image: `${PREFIX}-image`,
    imageMobile: `${PREFIX}-imageMobile`,
    window: `${PREFIX}-window`,
    windowText: `${PREFIX}-windowText`,
    secaconBefore: `${PREFIX}-secaconBefore`,
    secaconAfter: `${PREFIX}-secaconAfter`,
    titleBefore: `${PREFIX}-titleBefore`,
    titleAfter: `${PREFIX}-titleAfter`
};

const Root = styled('div')(({theme}) => ({
    [`& .${classes.imageContainer}`]: {
        position: "fixed",
        height: "60vh",
        width: "100%",
        overflowX: "hidden",
        top: 0,
        zIndex: -1,
        [theme.breakpoints.down('md')]: {
            position: "relative",
            height: "30vh",
        }
    },

    [`& .${classes.image}`]: {
        position: "absolute",
        display: "block",
        top: 150, right: 0, bottom: 0, left: 0,
        margin: "auto",
        maxWidth: "100%",
        overflowX: "hidden",
        transform: "rotate(-0.7deg) scale(1.05)",
        [theme.breakpoints.down('xl')]: {
            transform: "rotate(-0.7deg) scale(2)",
            top: 300, left: 50
        },
    },

    [`& .${classes.imageMobile}`]: {
        position: "absolute",
        display: "block",
        top: 100, right: 0, bottom: 0, left: -400,
        margin: "auto",
        minHeight: "100%",
        width: "calc(100vh + 100vw)",
        overflowX: "hidden"
    },

    [`& .${classes.window}`]: {
        height: "30vh",
        width: "100%",
        maxWidth: "100%",
        background: "radial-gradient(ellipse, rgba(9,32,48,0.8) 0%, rgba(13,45,68,0.8) 37%, rgba(42,166,255,0.3) 100%)",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down('md')]: {
            position: "absolute",
            maxWidth: "100%",
            top: 70, left: 0,
        }
    },

    [`& .${classes.windowText}`]: {
        justifyContent: "center",
        alignItems: "center",
        height: "30vh",
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
    },

    [`& .${classes.secaconBefore}`]: {
        filter: "opacity(0%)",
        fontFamily: "Eurostile LT, sans-serif",
        fontSize: "100px",
        transform: "translate(-70px, 0)",
        [theme.breakpoints.down('xl')]: {
            transform: "translate(-50px, 0)",
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: "75px",
            transform: "translate(-40px, 0)",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "35px",
            transform: "translate(-20px, 0)",
        },
        lineHeight: "1.2",
    },

    [`& .${classes.secaconAfter}`]: {
        transition: "2s",
        fontSize: "100px",
        [theme.breakpoints.down('lg')]: {
            fontSize: "75px",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "35px",
        },
        filter: "opacity(100%)",
        fontFamily: "Eurostile LT, sans-serif",
        lineHeight: "1.2",
    },

    [`& .${classes.titleBefore}`]: {
        color: "#efefef00",
        fontSize: "35px",
        transform: "translate(70px, 0)",
        [theme.breakpoints.down('xl')]: {
            transform: "translate(50px, 0)",
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: "25px",
            transform: "translate(40px, 0)",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "13px",
            transform: "translate(20px, 0)",
        },
        fontFamily: "Eurostile LT, sans-serif",
    },

    [`& .${classes.titleAfter}`]: {
        color: "#efefef",
        transition: "2s",
        fontSize: "35px",
        [theme.breakpoints.down('lg')]: {
            fontSize: "25px",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "13px",
        },
        position: "relative",
        left: "0",
        top: "0",
        fontFamily: "Eurostile LT, sans-serif"
    }
}));

export default function PageHeader(props: PageHeaderModel) {

    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [titleAnimation, setTitleAnimation] = useState(false);

    const [backgroundImageLoading, setBackgroundImageLoading] = useState(true);

    return (
        <Root>
            <div className={classes.imageContainer}>
                <img src={Office}
                     alt="Büro"
                     className={isMobileScreen ? classes.imageMobile : classes.image}
                     onLoad={() => {
                         setBackgroundImageLoading(false);
                         setTitleAnimation(true);
                     }}/>
            </div>
            <div className={classes.window}
                 style={backgroundImageLoading ? {background: ""} : {background: "radial-gradient(ellipse, rgba(9,32,48,0.8) 0%, rgba(13,45,68,0.8) 37%, rgba(42,166,255,0.3) 100%)"}}>
                <div className={classes.windowText}>
                    <div style={{display: "flex"}}>
                        <Typography variant="h1"
                                    className={!titleAnimation ? classes.secaconBefore : classes.secaconAfter}
                                    style={{color: "#f6f6f6"}}
                                    noWrap
                        >
                            {props.whiteTitle}
                        </Typography>
                        <Typography variant="h1"
                                    className={!titleAnimation ? classes.secaconBefore : classes.secaconAfter}
                                    style={{color: "#4aa6ff"}}
                                    noWrap
                        >
                            {props.blueTitle}
                        </Typography>
                    </div>
                    <Typography variant="h3" className={!titleAnimation ? classes.titleBefore : classes.titleAfter}>
                        {props.subtitle}
                    </Typography>
                </div>
            </div>
        </Root>
    );
}