import React from "react";
import {styled} from '@mui/material/styles';
import {Card, CardActions, CardContent, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import {isIE} from "react-device-detect";

import {ClientModel, EmployeesModel, PartnerModel} from "../../services/Models";

const PREFIX = 'CompanyCards';

const classes = {
    profileText: `${PREFIX}-profileText`,
    employeeCardContent: `${PREFIX}-employeeCardContent`,
    referenceCardContent: `${PREFIX}-referenceCardContent`,
    logo: `${PREFIX}-logo`,
    clientCardContent: `${PREFIX}-clientCardContent`,
    clientCardAction: `${PREFIX}-clientCardAction`
};

const Root = styled("div")(({theme}) => ({
    [`& .${classes.profileText}`]: {
        marginTop: "10px",
    },

    [`& .${classes.employeeCardContent}`]: {
        [theme.breakpoints.down('md')]: {
            padding: 0,
            paddingTop: 10
        }
    },

    [`& .${classes.referenceCardContent}`]: {
        width: "auto",
        height: 250,
        [theme.breakpoints.down('xl')]: {
            height: 180
        },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },

    [`& .${classes.logo}`]: {
        maxWidth: "95%",
        minWidth: "60%",
        height: "auto",
        maxHeight: "99%",
        display: "flex",
        alignSelf: "center",
    },

    [`& .${classes.clientCardContent}`]: {
        height: 140,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
    },

    [`& .${classes.clientCardAction}`]: {
        height: 100,
        display: "flex",
        flexDirection: "column"
    }
}));

export function Employees(props: { employees: EmployeesModel[] }) {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const employees = props.employees;

    const MappedEmployees = employees.map((info: EmployeesModel, key: number) => {
        return (
            <Grid item xs={12} lg={6} key={key}>
                <Grid container>
                    <Grid xs={12} md={4} item>
                        <CardContent style={isSmallScreen ? {display: "flex", justifyContent: "center"} : {}}>
                            <img alt={info.name} src={info.image} style={{maxWidth: "100%", maxHeight: "100%"}}/>
                        </CardContent>
                    </Grid>
                    <Grid xs={12} md={8} item>
                        <Root>
                            <CardContent className={classes.employeeCardContent}>
                                <Typography variant="h6">{info.name}</Typography>
                                <Typography variant="subtitle2">{info.function}</Typography>
                                <Typography variant="body1" className={classes.profileText}>{info.text}</Typography>
                            </CardContent>
                        </Root>
                    </Grid>
                </Grid>
            </Grid>
        )
    });
    return <>{MappedEmployees}</>;
}

export function Partner(props: { partner: PartnerModel[] }) {

    const partner = props.partner;

    const MappedPartner = partner.map((info: PartnerModel, key: number) => {
        return (
            <Grid item xs={6} md={4} key={key}>
                <Card elevation={3}>
                    <Root>
                        <CardContent className={classes.referenceCardContent}>
                            <img src={info.logo} alt={"Profilephot of: " + info.logo} className={classes.logo} style={isIE ? {height: 130} : {}}/>
                            <Typography variant="h6" align="center">
                                {info.title}
                            </Typography>
                        </CardContent>
                    </Root>
                </Card>
            </Grid>
        )
    });
    return <>{MappedPartner}</>
}

export function Clients(props: { client: ClientModel[] }) {

    const client = props.client;

    const theme = useTheme();

    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

    function Mobile(info: { name: string, text: string }) {
        return (
            <>
                <Typography variant="body1" align="center">
                    <strong>
                        {info.name}
                    </strong>
                </Typography>
                <Typography variant="subtitle2" align="center" style={{paddingBottom: "30px"}}>
                    {info.text}
                </Typography>
            </>
        )
    }

    function Desktop(info: { name: string, text: string }) {
        return (
            <>
                <Typography variant="h6" align="center">
                    {info.name}
                </Typography>
                <Typography variant="body2" align="center" style={{paddingBottom: "30px"}}>
                    {info.text}
                </Typography>
            </>
        )
    }

    const MappedClient = client.map((info: ClientModel, key: number) => {
        return (
            <Grid item xs={6} md={4} lg={2} key={key}>
                <Card elevation={3}>
                    <Root>
                        <CardContent className={classes.clientCardContent}>
                            <img src={info.logo}
                                 alt={"Profilephot of: " + info.logo}
                                 className={classes.logo} style={isIE ? {height: 'unset'} : {}}
                            />
                        </CardContent>

                        {isMobileScreen ?
                            (<CardActions className={classes.clientCardAction} style={{height: "8.8em"}}>
                                <Mobile name={info.name} text={info.text}/>
                            </CardActions>)
                            :
                            (<CardActions className={classes.clientCardAction}>
                                <Desktop name={info.name} text={info.text}/>
                            </CardActions>)
                        }
                    </Root>
                </Card>
            </Grid>
        )
    });
    return <>{MappedClient}</>
}