import {MutableRefObject, useCallback, useRef} from "react";

export function lockScroll(enable: boolean) {

    if (enable) {
        const originalStyle = window.getComputedStyle(document.body).overflow;

        document.body.style.overflow = 'hidden';

        return (
            () => {
                document.body.style.overflow = originalStyle
            }
        )
    } else {
        const noScrollStyle = window.getComputedStyle(document.body).overflow;

        document.body.style.overflow = 'visible';

        return (
            () => {
                document.body.style.overflow = noScrollStyle
            }
        )
    }
}

export function useDebounce<T extends any[]>(func: (...params: T) => void, timeout: number): (...params: T) => void {

    let initialCallDone = useRef(false);

    let timer: MutableRefObject<NodeJS.Timeout | undefined> = useRef();

    return useCallback((...params: T) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        if (!initialCallDone.current) {
            func(...params);
            initialCallDone.current = true;
        }

        timer.current = setTimeout(() => {
            func(...params);
            initialCallDone.current = false;
        }, timeout);
    }, [func, timeout]);
}
