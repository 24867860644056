import React from "react";
import {styled, useTheme} from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Container, useMediaQuery} from "@mui/material";
import { useNavigate } from "react-router";

const PREFIX = 'Footer';

const classes = {
    main: `${PREFIX}-main`,
    container: `${PREFIX}-container`,
    gridItem: `${PREFIX}-gridItem`,
    contactContainer: `${PREFIX}-contactContainer`,
    linksContainer: `${PREFIX}-linksContainer`,
    links: `${PREFIX}-links`
};

const Root = styled('div')(({theme}) => ({
    [`&.${classes.main}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        paddingTop: 8
    },

    [`& .${classes.container}`]: {
        padding: "25px 25px 15px",
        maxWidth: "100%",
    },

    [`& .${classes.gridItem}`]: {
        display: "flex",
        justifyContent: "center"
    },

    [`& .${classes.contactContainer}`]: {
        width: 250
    },

    [`& .${classes.linksContainer}`]: {
        display: "flex",
        justifyContent: "center",
        paddingTop: 10
    },

    [`& .${classes.links}`]: {
        fontSize: 13,
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline"
        }
    }
}));


export default function Footer() {

    const theme = useTheme();
    const navigate = useNavigate();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

    function handleNavigate(target: string) {
        window.scrollTo(0, 0);
        navigate(target);
    }

    return (
        <Root className={classes.main}>
            <Container maxWidth={false} style={{maxWidth: isMobileScreen ? "100%" : "80%"}}>
                <Grid container className={classes.container}>
                    <Grid item xs={12} md={6} className={classes.gridItem}>
                        <div className={classes.contactContainer}>
                            <Typography variant="h5">
                                <strong>
                                    Kontakt
                                </strong>
                            </Typography>
                            <Typography variant="body1" style={{padding: "15px 0px"}}>
                                Secacon Gygli Engineering GmbH <br/>
                                St. Jakobsstrasse 41 <br/>
                                CH-4132 Muttenz <br/>
                                <a href="mailto:info@secacon.com"
                                   style={{color: "#ffffff"}}
                                >
                                    info@secacon.com
                                </a>
                                <br/>
                                <a href="tel: +41 61 462 16 00"
                                   style={{color: "#ffffff", textDecoration: "none"}}
                                >
                                    +41 61 462 16 00
                                </a>
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6} className={classes.gridItem}>
                        <div className={classes.contactContainer}>
                            <Typography variant="h5">
                                <strong>
                                    Support
                                </strong>
                            </Typography>
                            <Typography variant="body1" style={{paddingTop: "15px"}}>
                                Geschäftszeiten <br/>
                                Montag - Freitag <br/>
                                08:00 - 17:00<br/>
                                <a href="mailto:support@secacon.com" style={{color: "#ffffff"}}>support@secacon.com</a>
                                <br/>
                                <a href="tel: +41 61 462 16 05" style={{color: "#ffffff", textDecoration: "none"}}>+41 61 462 16 05</a>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} className={classes.linksContainer}>
                        <Typography className={classes.links} onClick={() => handleNavigate("/company")}>Über uns</Typography>
                        <Typography style={{fontSize: 13}}>&nbsp;|&nbsp;</Typography>
                        <Typography className={classes.links} onClick={() => handleNavigate("/privacy-statement")}>Datenschutzerklärung</Typography>
                        <Typography style={{fontSize: 13}}>&nbsp;|&nbsp;</Typography>
                        <Typography style={{fontSize: 13}}>Secacon 2024</Typography>
                    </Grid>
                </Grid>
            </Container>
        </Root>
    );
}