import React, {useState} from 'react'
import {styled} from '@mui/material/styles';
import {Box, Container, Grid, Typography, useMediaQuery, useTheme,} from '@mui/material'
import {CARD_WIDTH, MappedCards, TitleCard} from "./elements/HomePageCards";

import Office from "../resources/Office.jpg";
import Consulting from "../resources/unsplash-pictures/Consulting_home.jpg";
import Engineering from "../resources/unsplash-pictures/Engineering_home.jpg";
import Coaching from "../resources/unsplash-pictures/Iso_home.jpg";
import Secasign from "../resources/unsplash-pictures/Secasign_home.jpg";
import SecasignCli from "../resources/unsplash-pictures/Secasign-CLI_home.jpg";

import '../index.css';
import {useDocumentTitle} from "../services/DocumentTitle";

const PREFIX = 'HomePage';

const classes = {
    main: `${PREFIX}-main`,
    body: `${PREFIX}-body`,
    mainBody: `${PREFIX}-mainBody`,
    cardGrid: `${PREFIX}-cardGrid`,
    imageContainer: `${PREFIX}-imageContainer`,
    image: `${PREFIX}-image`,
    imageMobile: `${PREFIX}-imageMobile`,
    window: `${PREFIX}-window`,
    windowText: `${PREFIX}-windowText`,
    secaconBefore: `${PREFIX}-secaconBefore`,
    secaconAfter: `${PREFIX}-secaconAfter`,
    titleBefore: `${PREFIX}-titleBefore`,
    titleAfter: `${PREFIX}-titleAfter`,
    welcome: `${PREFIX}-welcome`
};

const StyledBox = styled(Box)(({theme}) => ({
    [`& .${classes.main}`]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    [`& .${classes.body}`]: {
        backgroundColor: theme.palette.background.default,
    },

    [`& .${classes.mainBody}`]: {
        maxWidth: "80%",
        backgroundColor: "#00000000",
        padding: 0,
    },

    [`& .${classes.cardGrid}`]: {
        maxWidth: `${CARD_WIDTH * 3 + 8 * 6}px `, // (card width * 2 + card padding * 6)
        display: "flex",
        justifyContent: "center",
        margin: "20px",
        [theme.breakpoints.down('lg')]: {
            maxWidth: `${CARD_WIDTH * 2 + 8 * 4}px `, // (card width * 2 + card padding * 4)
        },
    },

    [`& .${classes.imageContainer}`]: {
        position: "relative",
        height: "calc(100vh - 95px)",
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        zIndex: -1,
        [theme.breakpoints.down('lg')]: {
            height: "calc(100vh - 70px)",
        }
    },

    [`& .${classes.image}`]: {
        position: "absolute",
        display: "block",
        top: 100, right: 0, bottom: 0, left: 0,
        margin: "auto",
        maxWidth: "100%",
        overflow: "hidden",
        transform: "rotate(-0.7deg) scale(1.05)",
        [theme.breakpoints.down('lg')]: {
            transform: "rotate(-0.7deg) scale(2.2)",
            top: 0, left: 50
        },
    },

    [`& .${classes.imageMobile}`]: {
        position: "absolute",
        display: "block",
        top: 0, right: 0, bottom: 0, left: -200,
        margin: "auto",
        minHeight: "100%",
        width: "calc(100vh + 100vw)",
        overflow: "hidden",
        transform: "rotate(-0.7deg) scale(1.05)",
    },

    [`& .${classes.window}`]: {
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        maxWidth: "100vw",
        top: 0, left: 0
    },

    [`& .${classes.windowText}`]: {
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 200px)", //set bit higher than center to avoid not centered illusion
        width: "auto",
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
    },

    [`& .${classes.secaconBefore}`]: {
        maxWidth: "55vw",
        filter: "opacity(0%)",
        fontFamily: "Eurostile LT, sans-serif",
        fontSize: "150px",
        [theme.breakpoints.down('md')]: {
            fontSize: "75px",
            transform: "translate(-40px, 0)",
        },
        lineHeight: "1",
        transform: "translate(-100px, 0)"
    },

    [`& .${classes.secaconAfter}`]: {
        maxWidth: "55vw",
        transition: "2s",
        fontSize: "150px",
        [theme.breakpoints.down('md')]: {
            fontSize: "75px",
        },
        filter: "opacity(100%)",
        fontFamily: "Eurostile LT, sans-serif",
        lineHeight: "1",
    },

    [`& .${classes.titleBefore}`]: {
        color: "#efefef00",
        fontSize: "40px",
        transform: "translate(100px, 0)",
        [theme.breakpoints.down('md')]: {
            fontSize: "23px",
            transform: "translate(40px, 0)",
        },
        fontFamily: "Eurostile LT, sans-serif",
    },

    [`& .${classes.titleAfter}`]: {
        color: "#efefef",
        transition: "2s",
        fontSize: "40px",
        [theme.breakpoints.down('md')]: {
            fontSize: "23px",
        },
        position: "relative",
        left: "0",
        top: "0",
        fontFamily: "Eurostile LT, sans-serif"
    },

    [`& .${classes.welcome}`]: {
        paddingTop: "50px",
        fontFamily: "Eurostile LT, sans-serif",
        fontSize: 85,
        [theme.breakpoints.down('md')]: {
            fontSize: 55,
        },
    }
}));

const Expertises = [
    {
        title: "Sicherheitsberatung",
        content: "Dank unserer jahrzehntelangen Erfahrung im Bereich der IT Security sind wir bestens geeignet, um Ihre gesamte IT in Form eines umfassenden Security Audits genau zu prüfen. Lassen Sie sich von unseren unabhängigen Experten beraten und decken Sie bisher unbekannte Sicherheitslücken auf.",
        image: `${Consulting}`,
        url: "/consulting",
    },
    {
        title: "ISO-27000 Coaching",
        content: "Secacon bietet Ihrem Unternehmen massgeschneiderte Coaching-Dienstleistungen für effiziente Erst- und Nachzertifizierungung. Ausserdem stellen wir zuverlässige Verbindungen zu ISO-27000-Zertifizierern für Sie her.",
        image: `${Coaching}`,
        url: "/iso"
    },
    {
        title: "Engineering",
        content: "Benötigen Sie Unterstützung dei der Erweiterung Ihres Sicherheitssystems? Gerne unterstützen Sie unsere kompetenten Experten beim entwickeln von diversen Applikationen im IT-Security Bereich.",
        image: `${Engineering}`,
        url: "/engineering",
    },
]

const Solutions = [
    {
        title: "Secasign",
        content: "Mit Secasign bieten wir Ihnen ein erstklassiges Tool zur Archivierung all Ihrer Dokumente. Secasign vereint die digitale Dokumentensignierung von Secasign-CLI und die Möglichkeit Ihre Dokumente geordnet zu archivieren und wieder finden zu können.",
        image: `${Secasign}`,
        url: "/secasign",
    },
    {
        title: "Secasign-CLI",
        content: "Die Produkte der Secasign-CLI-Familie von Secacon Gygli Engineering generieren und verifizieren digitale Signaturen für beliebige Dokumente auf zahlreichen System-Plattformen. Zeitstempel können lokal erzeugt oder von einer qualifizierten Time Stamp Authority bezogen werden.",
        image: `${SecasignCli}`,
        url: "/secasign-cli",
    }
]

export default function HomePage() {

    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [titleAnimation, setTitleAnimation] = useState(false);

    const [backgroundImageLoading, setBackgroundImageLoading] = useState(true);

    useDocumentTitle("Secacon - Gygli Engineering", "Secacon bietet Ihnen Beratung, Lösungen und Entwicklung von IT-Security relevanten Applikationen.");

    return (
        <StyledBox>
            {/*<div className={classes.imageContainer}>*/}
            {/*    <img src={Office} alt="Büro" className={isMobileScreen ? classes.imageMobile : classes.image} onLoad={() => {*/}
            {/*        setBackgroundImageLoading(false);*/}
            {/*        setTitleAnimation(true)*/}
            {/*    }}/>*/}
            {/*</div>*/}
            {/*<div className={classes.window} style={backgroundImageLoading ? {background: ""} : {background: "radial-gradient(ellipse, rgba(9,32,48,0.8) 0%, rgba(13,45,68,0.8) 37%, rgba(42,166,255,0.3) 100%)"}}>*/}
            {/*    <div className={classes.windowText}>*/}
            {/*        <div style={{display: "flex"}}>*/}
            {/*            <Typography variant="h1" className={!titleAnimation ? classes.secaconBefore : classes.secaconAfter} style={{color: "#f6f6f6"}} noWrap>Seca</Typography>*/}
            {/*            <Typography variant="h1" className={!titleAnimation ? classes.secaconBefore : classes.secaconAfter} style={{color: "#4aa6ff"}} noWrap>con</Typography>*/}
            {/*        </div>*/}
            {/*        <Typography variant="h2" className={!titleAnimation ? classes.titleBefore : classes.titleAfter}>Ihr Partner für IT-Security</Typography>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div>
                <div className={classes.imageContainer}>
                    <img src={Office} alt="Büro" className={isMobileScreen ? classes.imageMobile : classes.image} onLoad={() => {
                        setBackgroundImageLoading(false);
                        setTitleAnimation(true)
                    }}/>
                    <div className={classes.window} style={backgroundImageLoading ? {background: ""} : {background: "radial-gradient(ellipse, rgba(9,32,48,0.8) 0%, rgba(13,45,68,0.8) 37%, rgba(42,166,255,0.3) 100%)"}}>
                        <div className={classes.windowText}>
                            <div style={{display: "flex"}}>
                                <Typography variant="h1" className={!titleAnimation ? classes.secaconBefore : classes.secaconAfter} style={{color: "#f6f6f6"}} noWrap>Seca</Typography>
                                <Typography variant="h1" className={!titleAnimation ? classes.secaconBefore : classes.secaconAfter} style={{color: "#4aa6ff"}} noWrap>con</Typography>
                            </div>
                            <Typography variant="h2" className={!titleAnimation ? classes.titleBefore : classes.titleAfter}>Ihr Partner für IT-Security</Typography>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.body}>
                <Container maxWidth={false} className={classes.mainBody}>
                    <div className={classes.main}>
                        <Typography variant="h2" color="textPrimary" className={classes.welcome} align="center">Willkommen bei Secacon</Typography>
                    </div>
                    <div>
                        <Grid container style={{display: "flex", justifyContent: "center"}}>
                            <Grid container item className={classes.cardGrid}>
                                <TitleCard title="Kompetenzen"/>
                                <MappedCards content={Expertises}/>
                            </Grid>
                            <Grid container item className={classes.cardGrid}>
                                <TitleCard title="Lösungen"/>
                                <MappedCards content={Solutions}/>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
        </StyledBox>
    );
}