import React from "react";
import {styled} from '@mui/material/styles';
import {Container, Divider, Fab, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Clients, Employees, Partner} from "../elements/CompanyCards";
import {Link} from "react-router-dom";

import WALTER from "../../resources/staff_pictures/walter_downSized.jpg";
import SIMON from "../../resources/staff_pictures/simon_downSized.jpg";
import SEAN from "../../resources/staff_pictures/sean_downSized.jpg";

import QuoVadis from "../../resources/partner/QuoVadis.png";
import Gemalto from "../../resources/partner/gemalto-logo.png";
import Compumatica from "../../resources/partner/compumatica-logo.png";
import Ironforge from "../../resources/partner/Logo_Irongforge_white-Kopie.png";
import Microsoft from "../../resources/partner/Microsoft-Logo.jpg";

import CHEidgenossenschaft from "../../resources/clients/790px-Logo_der_Schweizerischen_Eidgenossenschaft.svg.png";
import BaslerKB from "../../resources/clients/Logo_Basler_Kantonalbank.svg.png";
import UNIBA from "../../resources/clients/Logo_Universitaetsspital_Basel.svg.png";
import CLER from "../../resources/clients/Cler.jpeg";
import SteuerverwaltungBS from "../../resources/clients/logo-steuerverwaltung-print.png";
import SwissLife from "../../resources/clients/Logo_SwissLife.png";
import Exceet from "../../resources/clients/exceet.svg";
import Infosec from "../../resources/clients/infosec.png";
import Helvetia from "../../resources/clients/Helvetia_Versicherung_logo.svg.png";
import Accarda from "../../resources/clients/accarda.png";
import Mobiliar from "../../resources/clients/mobiliar.jpeg";
import Atos from "../../resources/clients/Atos_Logo.png";
import SIX from "../../resources/clients/six.jpeg";
import Interact from "../../resources/clients/interact.png";
import EM from "../../resources/clients/logo-em-rgb-resized.png";
import Uri from "../../resources/clients/uri.png";
import SQTS from "../../resources/clients/SQTS_logo.png";
import CompanyTimeline from "../elements/CompanyTimeline";
import {useDocumentTitle} from "../../services/DocumentTitle";

const PREFIX = 'About';

const classes = {
    body: `${PREFIX}-body`,
    mainBody: `${PREFIX}-mainBody`,
    gridItem: `${PREFIX}-gridItem`,
    timelinePos: `${PREFIX}-timelinePos`,
    secaconText: `${PREFIX}-secaconText`
};

const Root = styled('div')(({theme}) => ({
    [`&.${classes.body}`]: {
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up('md')]: {
            minHeight: "calc(100vh - 221px - 95px)" //substract height of footer and header
        }
    },

    [`& .${classes.mainBody}`]: {
        maxWidth: "80%",
        [theme.breakpoints.down('md')]: {
            maxWidth: "98%"
        },
        padding: "20px"
    },

    [`& .${classes.gridItem}`]: {
        padding: "20px",
    },

    [`& .${classes.timelinePos}`]: {
        [theme.breakpoints.up('lg')]: {
            display: "flex",
            float: "right",
        }
    },

    [`& .${classes.secaconText}`]: {
        paddingRight: 150,
        [theme.breakpoints.down('xl')]: {
            paddingRight: 0
        },
    }
}));

const employees = [
    {
        name: "Walter Gygli",
        image: `${WALTER}`,
        text: "Nach seinem Ingenieursstudium an der ETH hat sich Walter fortlaufend weitergebildet. So erwarb er" +
            " das eidgenössische Diplom für Betriebsökonomie. Seine grosse Leidenschaft für die Technik motivierte ihn" +
            " sich vertieft mit IT Security auseinanderzusetzen. Im Jahr 2008 wurde er ISO 27000 Lead Auditor. Des Weiteren" +
            " absolvierte er im Jahr 2007 an der ISACA die Prüfung zum Certified Information Security Manager (CISM)." +
            " Mit seiner langjährigen Erfahrung begleitet er mehrere IT Security Projekte diverser Firmen als Berater und Projektleiter.",
        function: "Inhaber & CEO"
    },
    {
        name: "Simon Wächter",
        image: `${SIMON}`,
        text: "Im Jahr 2011 begann Simon bei uns seine Berufslehre als Informatiker EFZ (Fachrichtung" +
            " Applikationsentwicklung) mit berufsbegleitender Berufsmaturität. Nach dem Abschluss vertiefte er seine" +
            " Ausbildung im berufsbegleitenden Informatikstudium an der Fachhochschule Nordwestschweiz, wo er anfangs 2020" +
            " seinen Bachelor erhielt. Der Unterhalt und die Weiterentwicklung von" +
            " Secasign unterliegt seiner Verantwortung.",
        function: "IT-Security Spezialist & Software Engineer"
    },
    {
        name: "Sean Mengis",
        image: `${SEAN}`,
        text: "Für ein Jahr war Sean bei uns als Praktikant engagiert und ist nun Teilzeit angestellt. Um seine Kentnisse zu vertiefen studiert er seit Herbst 2021" +
            " Informatik an der Fachhochschule Nordwestschweiz. Sein Tätigkeitsbereich umfasst Frontendentwicklung und Kundensupport.",
        function: "Werkstudent & Software Engineer"
    },
]

const partner = [
    {
        logo: `${QuoVadis}`,
    },
    {
        logo: `${Gemalto}`,
    },
    {
        logo: `${Ironforge}`,
    },
    {
        logo: `${Compumatica}`,
    },
    {
        logo: `${Microsoft}`,
    },
]

const clients = [
    {
        logo: `${CHEidgenossenschaft}`,
        name: "Astra",
        text: "Begleitung WTO Ausschreibung"
    },
    {
        logo: `${BaslerKB}`,
        name: "Basler Kantonalbank",
        text: "Digitale Signatur"
    },
    {
        logo: `${CHEidgenossenschaft}`,
        name: "VBS",
        text: "Diverse Beratungen und Lösungen seit 1997"
    },
    {
        logo: `${UNIBA}`,
        name: "Universitätsspital Basel",
        text: "VeKa Zugriff"
    },
    {
        logo: `${CLER}`,
        name: "Bank CLER",
        text: "Digitale Signatur"
    },
    {
        logo: `${SteuerverwaltungBS}`,
        name: "Steuerverwaltung Baselstadt",
        text: "Digitale Signatur"
    },
    {
        logo: `${SwissLife}`,
        name: "Swiss Life",
        text: "Beratung"
    },
    {
        logo: `${Uri}`,
        name: "Kanton Uri",
        text: "Secasign"
    },
    {
        logo: `${Exceet}`,
        name: "exceet Card Group",
        text: "CA Unterstützung"
    },
    {
        logo: `${Infosec}`,
        name: "Infosec Global",
        text: "Beratung, Engineering"
    },
    {
        logo: `${SQTS}`,
        name: "SQTS",
        text: "Secasign"
    },
    {
        logo: `${Helvetia}`,
        name: "Helvetia",
        text: "Beratung"
    },
    {
        logo: `${Accarda}`,
        name: "Accarda",
        text: "PCI Engineering"
    },
    {
        logo: `${Mobiliar}`,
        name: "Mobiliar",
        text: "CA Unterstützungen"
    },
    {
        logo: `${EM}`,
        name: "Elektro Material",
        text: "Secasign"
    },
    {
        logo: `${Atos}`,
        name: "Atos",
        text: "Engineering"
    },
    {
        logo: `${SIX}`,
        name: "SIX",
        text: "PCI Engineering, Sicherung elektronischer Transaktionen"
    },
    {
        logo: `${Interact}`,
        name: "Interact",
        text: "Secasign"
    },
]

export default function About() {

    useDocumentTitle("Über Secacon", "Seit über 17 Jahren bieten wir als Secacon Gygli Engineering GmbH unsere Dienste und Lösungen für Kunden verschiedenster Art an. Schon von Beginn an lag unser Fokus einerseits auf der Beratung von Unternehmen, die ihre IT Security verbessern wollten, und andererseits auf der Entwicklung von spezifisch auf Kundenwünsche angefertigten IT Security Lösungen.")

    const theme = useTheme();

    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Root className={classes.body}>
            <Container className={classes.mainBody}>
                <Grid container spacing={3}>
                    <Grid item container xs={12} className={classes.gridItem}>

                        <Grid item xs={12} md={8} style={{position: "relative"}}>
                            <Typography variant="h1" style={{zIndex: 10, fontSize: 38}}>
                                Über Secacon
                            </Typography>

                            <Typography variant="body1" className={classes.secaconText}>
                                Seit über 20 Jahren bieten wir als Secacon Gygli Engineering GmbH unsere Dienste und
                                Lösungen für Kunden verschiedenster Art an. Schon von Beginn an lag unser Fokus einerseits
                                auf der Beratung von Unternehmen, die ihre IT Security verbessern wollten, und andererseits
                                auf der Entwicklung von spezifisch auf Kundenwünsche angefertigten IT Security Lösungen.
                                Durch die vielen Projekte, die wir für unsere Kunden wahrnehmen durften, konnten wir unseren
                                Erfahrungsschatz stetig erweitern. Wir freuen uns, dass wir mittlerweile in verschiedenen
                                Themengebieten der IT Security vertieftes Knowhow vorweisen können. Diese einzigartige
                                Expertise zeichnet uns aus und ist unsere grösste Stärke.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CompanyTimeline/>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.gridItem}>
                        <Divider style={{padding: "0 20px 0 20px"}}/>
                    </Grid>

                    <Grid item xs={12} className={classes.gridItem}>
                        <Typography variant="h4">
                            Mitarbeiter
                        </Typography>
                        <Grid container>
                            <Employees employees={employees}/>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.gridItem}>
                        <Divider style={{padding: "0 20px 0 20px"}}/>
                    </Grid>

                    <Grid item xs={12} className={classes.gridItem}>
                        <Typography variant="h4" style={{margin: "10px"}}>
                            Partner
                        </Typography>
                        <Grid container spacing={3}>
                            <Partner partner={partner}/>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.gridItem}>
                        <Divider style={{padding: "0 20px 0 20px"}}/>
                    </Grid>

                    <Grid item xs={12} className={classes.gridItem}>
                        <Typography variant="h4" style={{margin: "10px"}}>
                            Kunden
                        </Typography>
                        <Grid container spacing={3}>
                            <Clients client={clients}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Fab variant="extended"
                     style={{
                         backgroundColor: "rgba(73,165,255,1)",
                         color: "#f3f3f3",
                         position: "fixed",
                         bottom: 20,
                         right: 20
                     }}
                     component={Link} to="/contact"
                     onClick={(() => window.scrollTo(0, 0))}
                >
                    {!isMobileScreen ?
                        <strong>Kontakt</strong>
                        :
                        "Kontakt"
                    }
                </Fab>
            </Container>
        </Root>
    );
}