import React from "react";
import {styled} from '@mui/material/styles';
import {Card, Typography} from "@mui/material";
import {useDocumentTitle} from "../../services/DocumentTitle";

const PREFIX = 'PrivacyStatement';

const classes = {
    body: `${PREFIX}-body`,
    card: `${PREFIX}-card`,
    title: `${PREFIX}-title`,
    secaconText: `${PREFIX}-secaconText`
};

const Root = styled('div')(({theme}) => ({
    [`&.${classes.body}`]: {
        backgroundColor: theme.palette.background.default,
        display: "flex",

        [theme.breakpoints.up('md')]: {
            minHeight: "calc(100vh - 260px - 95px)" //substract height of footer and header
        }
    },

    [`& .${classes.card}`]: {
        margin: "auto",
        padding: 20,
        maxWidth: 500,
    },

    [`& .${classes.title}`]: {
        zIndex: 10,
        fontSize: 38,
        [theme.breakpoints.down('sm')]: {
            fontSize: 32,
        }
    },

    [`& .${classes.secaconText}`]: {
        paddingTop: 7
    }
}));

export default function PrivacyStatement() {

    useDocumentTitle("Datenschutz", "Als IT-Security Unternehmen sind wir uns der Wichtigkeit von Datenschutz bewusst und setzten alles daran ihre Daten anonym und vertraulich zu behandeln.")

    return (
        <Root className={classes.body}>
            <Card elevation={2} className={classes.card}>
                <Typography variant="h1" className={classes.title}>
                    Datenschutzerklärung
                </Typography>

                <Typography variant="body1" className={classes.secaconText}>
                    Diese Datenschutzerklärung gilt nur für die von Secacon gehostete Webseite www.secacon.com.
                    <br/>
                    <br/>
                    Als IT-Security Unternehmen sind wir uns der Wichtigkeit des Datenschutzes bewusst und setzen alles daran,
                    ihre Daten anonym und vertraulich zu behandeln. Unser Web-Service speichert lediglich technische Daten in Form
                    von: IP Adresse, verwendeter Browsertyp, Datum/Uhrzeit ihres Besuches und die aufgerufenen URLs. Diese Daten lassen keine Rückschlüsse auf eine konkrete Person zu.
                </Typography>
            </Card>
        </Root>
    );
}