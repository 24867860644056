import React, {FunctionComponent} from "react";
import {styled} from '@mui/material/styles';
import {Button, Menu, MenuItem} from "@mui/material";
import {Link} from "react-router-dom";

import {CustomMenuModel} from "../../services/Models";

const PREFIX = 'CustomMenu';

const classes = {
    button: `${PREFIX}-button`,
    menu: `${PREFIX}-menu`,
    menuItem: `${PREFIX}-menuItem`
};

const Root = styled('div')(({theme}) => ({
    [`& .${classes.button}`]: {
        color: theme.palette.primary.contrastText,
        textAlign: "center",
        fontSize: "inherit"
    },

    [`& .${classes.menu}`]: {
        pointerEvent: "none",
        elevation: 2,
    },

    [`& .${classes.menuItem}`]: {
        fontSize: "20px",
    }
}));


const CustomMenu: FunctionComponent<CustomMenuModel> = ((props: CustomMenuModel) => {

    const [anchor, setAnchor] = React.useState<Element | null>(null);

    function ScrollTop() {
        window.scrollTo(0, 0)
    }

    const MappedMenuItem = React.forwardRef(() => {                     // add forwardRef to pass down ref
        const Mapped = props.items.map((item) => {
            return (
                <MenuItem
                    key={item.name}
                    onClick={() => {
                        setAnchor(null);
                        ScrollTop();
                    }}
                    component={Link}
                    to={item.url}
                    className={classes.menuItem}
                >
                    {item.name}
                </MenuItem>
            )
        })
        return (
            <>{Mapped}</>
        )
    })

    return (
        <Root>
            <Button
                className={classes.button}
                onClick={
                    (event) => {
                        setAnchor(event.currentTarget);
                    }
                }
            >
                {props.menuName}
            </Button>
            <Menu
                elevation={0}
                open={Boolean(anchor)}
                anchorEl={anchor}
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                transformOrigin={{vertical: "top", horizontal: "center"}}
                onClose={() => setAnchor(null)}
                className={classes.menu}
                MenuListProps={{
                    onMouseLeave: () => setTimeout(() => setAnchor(null), 300)
                }}
                disableScrollLock
            >
                <MappedMenuItem/>
            </Menu>
        </Root>
    );
})

export default CustomMenu;