import React from "react";
import {styled} from '@mui/material/styles';
import {List, ListItem} from "@mui/material";
import CustomMenu from "./CustomMenu";

const PREFIX = 'Navigation';

const classes = {
    list: `${PREFIX}-list`,
    listItem: `${PREFIX}-listItem`
};

const Root = styled('div')(({theme}) => ({
    [`& .${classes.list}`]: {
        display: "flex",
        flexDirection: "row",
        maxWidth: "100%",
        justifyContent: "flex-end",
    },

    [`& .${classes.listItem}`]: {
        display: "flex",
        justifyContent: "center",
        width: "auto",
        fontSize: "23px",
        padding: "0px 10px",
        [theme.breakpoints.down('lg')]: {
            fontSize: "18px"
        }
    }
}));

export default function Navigation() {

    return (
        <Root>
            <List className={classes.list} disablePadding>
                <ListItem className={classes.listItem} disablePadding>
                    <CustomMenu menuName="Kompetenzen" items={[
                        {name: "Sicherheitsberatung", url: "/consulting"},
                        {name: "Engineering", url: "/engineering"},
                        {name: "ISO-27000 Coaching", url: "/iso"},
                    ]}
                    />
                </ListItem>
                <ListItem className={classes.listItem} disablePadding>
                    <CustomMenu menuName="Lösungen" items={[
                        {name: "Secasign", url: "/secasign"},
                        {name: "Secasign-CLI", url: "/secasign-cli"}
                    ]}/>
                </ListItem>
                <ListItem className={classes.listItem} style={{marginRight: "20px"}} disablePadding>
                    <CustomMenu menuName="Firma" items={[
                        {name: "Secacon", url: "/company"},
                        {name: "Kontakt", url: "/contact"},
                    ]}/>
                </ListItem>
            </List>
        </Root>
    );
}
