import React from 'react';
import {ThemeProvider} from "@mui/material";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Theme from "./services/Theme";

import HomePage from "./pages/HomePage";
import Header from "./pages/elements/Header";
import Footer from "./pages/elements/Footer";
import Secasign from "./pages/solutions/Secasign";
import SecasignCli from "./pages/solutions/SecasignCli";
import About from "./pages/company/About";
import Contact from "./pages/company/Contact";
import SecurityConsulting from "./pages/expertises/SecurityConsulting";
import ISO27000Coaching from "./pages/expertises/ISO27000Coaching";
import Engineering from "./pages/expertises/Engineering";
import IECheck from "./services/IEHeaderSpace";
import PrivacyStatement from "./pages/company/PrivacyStatement";

export default function App() {
    return (
        <ThemeProvider theme={Theme}>
            <BrowserRouter>
                <Header/>
                <IECheck/>
                <Routes>
                    {/*Home*/}
                    <Route path="/*" element={<HomePage/>}/>
                    <Route path="/Home" element={<HomePage/>}/>
                    {/*Expertises*/}
                    <Route path="/consulting" element={<SecurityConsulting/>}/>
                    <Route path="/iso" element={<ISO27000Coaching/>}/>
                    <Route path="/engineering" element={<Engineering/>}/>
                    {/*Solutions*/}
                    <Route path="/secasign" element={<Secasign/>}/>
                    <Route path="/secasign-cli" element={<SecasignCli/>}/>
                    {/*Firma*/}
                    <Route path="/company" element={<About/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/privacy-statement" element={<PrivacyStatement/>}/>
                </Routes>
                <Footer/>
            </BrowserRouter>
        </ThemeProvider>
    );
}
