import {useEffect} from "react";

export function useDocumentTitle(title: string, description: string) {
    useEffect(() => {
        function addMetaTag() {
            // Get an element if it exists already
            let element = document.querySelector('meta[name="description"]');

            // Check if the element exists
            if (element) {
                // If it does just change the content of the element
                element.setAttribute("content", description);
            } else {
                // It doesn't exist so lets make a HTML element string with the info we want and insert it into the head
                document.head.insertAdjacentHTML("beforeend", '<meta name="description" content={description}/>');
            }
        }

        document.title = title;
        addMetaTag();

    }, [title, description])
}
