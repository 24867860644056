import React from "react";
import {styled} from '@mui/material/styles';
import {lockScroll} from "./Utilities";
import {ImageViewerModel} from "./Models";
import {isIE} from "react-device-detect";

const PREFIX = 'ImageViewer';

const classes = {
    image: `${PREFIX}-image`,
    imageHover: `${PREFIX}-imageHover`,
    bigImageContainerOpen: `${PREFIX}-bigImageContainerOpen`,
    bigImageContainerClosed: `${PREFIX}-bigImageContainerClosed`,
    bigImageOpen: `${PREFIX}-bigImageOpen`,
    bigImageClosed: `${PREFIX}-bigImageClosed`,
    backgroundOpen: `${PREFIX}-backgroundOpen`,
    backgroundClosed: `${PREFIX}-backgroundClosed`,
    fitContent: `${PREFIX}-fitContent`
};

const Root = styled('div')(({theme}) => ({
    [`& .${classes.image}`]: {
        maxWidth: "100%",
        maxHeight: "100%",
        position: "relative",
        top: 0,
        transition: "0.6s",
        alignSelf: "center",
    },

    [`& .${classes.imageHover}`]: {
        maxWidth: "100%",
        maxHeight: "100%",
        position: "relative",
        top: 0,
        transition: "0.4s",
        filter: "brightness(0.5)",
        alignSelf: "center"
    },

    [`& .${classes.bigImageContainerOpen}`]: {
        position: "fixed",
        width: "100vw",
        height: "100vh",
        bottom: 0, left: 0,
        opacity: 1,
        zIndex: 9997,
    },

    [`& .${classes.bigImageContainerClosed}`]: {
        opacity: 0,
        width: 0,
        height: 0,
    },

    [`& .${classes.bigImageOpen}`]: {
        maxHeight: "90%",
        maxWidth: "90%",
        [theme.breakpoints.down('md')]: {
            maxHeight: "100%",
            maxWidth: "100%",
        },
        transition: "0.6s",
        opacity: 1,
        zIndex: 9998
    },

    [`& .${classes.bigImageClosed}`]: {
        width: 0,
        height: 0,
        transition: "0.6s",
        opacity: 0,
    },

    [`& .${classes.backgroundOpen}`]: {
        backgroundColor: "#00000080",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "0.6s",
        zIndex: 9998,
        [theme.breakpoints.down('md')]: {
            backgroundColor: "#000000",
            position: "relative",
            height: "120%",
            top: "-10%"
        },
    },

    [`& .${classes.backgroundClosed}`]: {
        backgroundColor: "#00000000",
        width: "100%",
        height: "100%",
        transition: "0.6s",
    },

    [`&.${classes.fitContent}`]: {
        height: "fit-content",
        maxHeight: "100%",
        width: "fit-content",
        maxWidth: "100%"
    }
}));

export default function ImageViewer(props: ImageViewerModel) {

    const [open, setOpen] = React.useState(false);
    const [hover, setHover] = React.useState(false);

    let border = props.border;

    function handleClick() {
        setOpen(prevState => !prevState)
    }

    React.useEffect(() => {
        lockScroll(open)
    }, [open])

    function handleEnter() {
        setHover(true)
    }

    function handleLeave() {
        setHover(false)
    }

    document.addEventListener("keydown", handleEsc);

    function handleEsc(ev: KeyboardEvent) {
        if (ev.key === "Escape") {
            setOpen(false)
        }
    }


    return (
        <Root className={classes.fitContent}>
            <img src={props.image} alt={props.alt}
                 className={hover ? classes.imageHover : classes.image}
                 onClick={handleClick}
                 onMouseEnter={handleEnter}
                 onMouseLeave={handleLeave}
                 style={!border ? {} : (hover ? {border: "1px solid #00000070"} : {border: "1px solid #00000030"})}
            />
            <div className={open ? classes.bigImageContainerOpen : classes.bigImageContainerClosed}>
                <div className={open ? classes.backgroundOpen : classes.backgroundClosed}
                     onClick={() => handleClick()}
                     style={isIE ? {backgroundColor: "#000000"} : {}}
                >
                    <img src={props.image}
                         alt={props.alt}
                         className={open ? classes.bigImageOpen : classes.bigImageClosed}
                    />
                </div>
            </div>
        </Root>
    );
}