import React, {useCallback, useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import {AppBar, Grid, Hidden, useMediaQuery, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import Navigation from "./Navigation";
import EditDrawer from "./SideDrawer";

import Logo from "../../resources/Secacon_Logo.png";
import {useLocation} from "react-router";
import {useDebounce} from "../../services/Utilities";

const PREFIX = 'Header';

const classes = {
    container: `${PREFIX}-container`,
    containerRetracted: `${PREFIX}-containerRetracted`,
    grid: `${PREFIX}-grid`,
    link: `${PREFIX}-link`,
    logo: `${PREFIX}-logo`,
    companyName: `${PREFIX}-companyName`,
    navigation: `${PREFIX}-navigation`,
    navigationContainer: `${PREFIX}-navigationContainer`
};

const StyledAppBar = styled(AppBar)(({theme}) => ({
    [`&.${classes.container}`]: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.contrastText,
        height: "95px",
        [theme.breakpoints.down('lg')]: {
            height: "70px",
        },
        position: "fixed",
        width: "100%",
        maxWidth: "100%",
        zIndex: 100,
        transition: "0.3s"
    },

    [`&.${classes.containerRetracted}`]: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.contrastText,
        height: "53px",
        position: "fixed",
        width: "100%",
        maxWidth: "100%",
        zIndex: 100,
        transition: "0.3s"
    },

    [`& .${classes.grid}`]: {
        height: "55px",
    },

    [`& .${classes.link}`]: {
        textDecoration: "none",
        boxShadow: "none",
        transition: "0.3s",
    },

    [`& .${classes.logo}`]: {
        height: "65px",
        width: "auto",
        padding: "8px",
        paddingLeft: "25px",
        [theme.breakpoints.down('lg')]: {
            height: "58px",
            padding: "5px",
        },
        [theme.breakpoints.down('md')]: {
            height: "52px",
            padding: "8px",
        }
    },

    [`& .${classes.navigation}`]: {
        display: "flex",
        alignItems: "center"
    },

    [`& .${classes.navigationContainer}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        transition: "0.3s"
    },

    [`& .${classes.companyName}`]: {
        fontFamily: "Eurostile LT, sans-serif",
        color: "#4aa6ff",
        padding: "0px 20px",
        fontSize: 37,
        height: 55
    },

    "@body": {
        margin: 0
    }
}));

export default function Header() {
    const theme = useTheme();
    const location = useLocation();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const [isHeaderRetracted, setHeaderRetracted] = useState(false);

    function handleClick() {
        window.scrollTo(0, 0);
    }

    const handleScroll = useCallback( () => {
        if (location.pathname !== "/" && location.pathname !== "/home" && location.pathname !== "/contact") {
            setHeaderRetracted(window.scrollY > 250 && !isMobileScreen);
        } else {
            setHeaderRetracted(false);
        }
    }, [location.pathname, isMobileScreen])

    const debouncedHandleScroll = useDebounce(handleScroll, 75);

    useEffect(() => {
        debouncedHandleScroll(); // Execute once after navigating

        window.addEventListener('scroll', debouncedHandleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', debouncedHandleScroll);
        };
    }, [debouncedHandleScroll]);

    return (
        <>
            <StyledAppBar className={isHeaderRetracted ? classes.containerRetracted : classes.container}>
                <Grid container style={isHeaderRetracted ? {height: 55} : {height: 95}}>
                    <Grid item xs={4} md={4} className={classes.navigation}>
                        <Link to="/home" onClick={handleClick} className={classes.link} style={isHeaderRetracted ? {transform: "scale(0.64)", transformOrigin: "top left", height: 55} : {}}>
                            <img src={Logo} className={classes.logo} style={isHeaderRetracted ? {height: 70} : {}} alt="Secacon logo"/>
                        </Link>
                    </Grid>

                    <Hidden lgUp>
                        <Grid item xs={8} md={8} className={classes.navigationContainer} style={isHeaderRetracted ? {height: 55} : {height: 70}}>
                            {isMobileScreen ? <EditDrawer/> : <Navigation/>}
                        </Grid>
                    </Hidden>
                    <Hidden lgDown>
                        <Grid item xs={8} md={8} className={classes.navigationContainer} style={isHeaderRetracted ? {height: 55} : {height: 95}}>
                            {isMobileScreen ? <EditDrawer/> : <Navigation/>}
                        </Grid>
                    </Hidden>
                </Grid>
            </StyledAppBar>
            <div style={{height: isSmallScreen ? 70 : 95, background: (location.pathname === "/company" || location.pathname === "/contact" || location.pathname === "/privacy-statement") ? "#fff" : "rgba(34, 104, 153, 0.5)"}}/> {/*Spaceholder*/}
        </>
    );
}