import React from "react";
import {styled} from '@mui/material/styles';
import {Card, CardContent, Typography} from "@mui/material";

const PREFIX = 'ISO27000Info';

const classes = {
    card: `${PREFIX}-card`
};

const StyledCard = styled(Card)(({theme}) => ({
    [`&.${classes.card}`]: {
        width: "30%",
        [theme.breakpoints.down('lg')]: {
            width: "100%"
        },
        float: "right",
        margin: "15px"
    }
}));

export default function ISO27000Info() {

    return (
        <StyledCard className={classes.card} elevation={7}>
            <CardContent>
                <Typography variant="h6">
                    <strong>
                        Was ist ISO-27000?
                    </strong>
                </Typography>
                <Typography variant="body1">Der international anerkannte Standard ISO 27000 steht als Qualitätsmerkmal
                    für die Informationssicherheit eines Unternehmens. Beurteilt werden verschiedene Teilbereiche wie
                    z.B. die Personal- und Netzwerksicherheit, die Informationssicherheit im Betrieb und die
                    Organisation der Informationssicherheit.
                    Dafür wird ein Information Security Management System (ISMS) verwendet. Das ISMS hat den Fokus u.a.
                    auf der Informationssicherheitspolitik des Unternehmens, der Risikobeurteilung und der
                    Risikobehandlung.
                </Typography>
            </CardContent>
        </StyledCard>
    );
}