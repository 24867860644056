import React from "react";
import {styled} from '@mui/material/styles';
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator} from "@mui/lab";
import {Typography, useMediaQuery, useTheme} from "@mui/material";

const PREFIX = 'CompanyTimeline';

const classes = {
    timeline: `${PREFIX}-timeline`,
    opposite: `${PREFIX}-opposite`,
    title: `${PREFIX}-title`,
    timelineItem: `${PREFIX}-timelineItem`
};

const Root = styled('div')(({theme}) => ({
    [`& .${classes.timeline}`]: {
        marginTop: 0,
    },

    [`& .${classes.opposite}`]: {
        [theme.breakpoints.up('lg')]: {
            flex: "unset"
        }
    },

    [`& .${classes.title}`]: {
        overflowWrap: "anywhere",
        [theme.breakpoints.down('xl')]: {
            width: 200
        },
        [theme.breakpoints.down('lg')]: {
            width: "unset"
        },
    },

    [`& .${classes.timelineItem}`]: {
        minHeight: 50,
    },

    ".MuiTimelineDot-root": {
        backgroundColor: "#49a5ff"
    }
}));

export default function CompanyTimeline() {

    const theme = useTheme();
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const isReallySmallScreen = window.screen.availWidth <= 380;
    const reallySmallFontSize = 10;

    return (
        <Root>
            <Timeline position={isMediumScreen ? "alternate" : "right"} className={classes.timeline}>
                <TimelineItem className={classes.timelineItem}>
                    <TimelineOppositeContent className={classes.opposite}>
                        <Typography color="textSecondary">2003</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot/>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography className={classes.title}
                                    style={isReallySmallScreen ? {fontSize: reallySmallFontSize} : {}}
                        >
                            Gründung
                        </Typography>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem className={classes.timelineItem}>
                    <TimelineOppositeContent className={classes.opposite}>
                        <Typography color="textSecondary">2006</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot/>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography className={classes.title}
                                    style={isReallySmallScreen ? {fontSize: reallySmallFontSize} : {}}
                        >
                            Secasign-CLI & Signierung von PDFs
                        </Typography>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem className={classes.timelineItem}>
                    <TimelineOppositeContent className={classes.opposite}>
                        <Typography color="textSecondary">2009</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot/>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography className={classes.title}
                                    style={isReallySmallScreen ? {fontSize: reallySmallFontSize} : {}}
                        >
                            Neuentwicklung File/Folder Encryption
                        </Typography>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem className={classes.timelineItem}>
                    <TimelineOppositeContent className={classes.opposite}>
                        <Typography color="textSecondary">2014</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot/>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography className={classes.title}
                                    style={isReallySmallScreen ? {fontSize: reallySmallFontSize} : {}}
                        >
                            Einführung Secasign
                        </Typography>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem className={classes.timelineItem}>
                    <TimelineOppositeContent className={classes.opposite}>
                        <Typography color="textSecondary">2015</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot/>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography className={classes.title}
                                    style={isReallySmallScreen ? {fontSize: reallySmallFontSize} : {}}
                        >
                            Begleitete ISO-27000 Zertifizierung
                        </Typography>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem className={classes.timelineItem}>
                    <TimelineOppositeContent className={classes.opposite}>
                        <Typography color="textSecondary">2018</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot/>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography className={classes.title}
                                    style={isReallySmallScreen ? {fontSize: reallySmallFontSize} : {}}
                        >
                            Projektleitung Ablösung Secure Center
                        </Typography>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem className={classes.timelineItem}>
                    <TimelineOppositeContent className={classes.opposite}>
                        <Typography color="textSecondary">2022</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot/>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography className={classes.title}
                                    style={isReallySmallScreen ? {fontSize: reallySmallFontSize} : {}}
                        >
                            Neuentwicklung Secasign
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </Root>
    );
}
