import {createTheme} from '@mui/material/styles';

const Theme = createTheme({
    palette: {
        primary: {
            light: "#ffffff",
            main: "#ffffff",
            dark: "#2b2b2b",
            contrastText: "#181818",
        },
        secondary: {
            light: "#4aa6ff",
            main: "#124363",
            dark: "#001d39",
            contrastText: "#ffffff",
        },
        background: {
            default: "#fffefc"
        }
    },
    typography: {
        fontFamily: "sans-serif"
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 650,
            md: 900,
            lg: 1330,
            xl: 1920,
        },
    },
});

export default Theme;