import React from "react";
import {styled} from '@mui/material/styles';
import {Container, Typography, useMediaQuery, useTheme} from "@mui/material";
import Meeting from "../../resources/unsplash-pictures/Illustration_consulting.jpg";
import PageHeader from "../elements/PageHeader";
import SecurityConsultingCard from "../elements/SecurityConsultingCard";
import {isIE} from "react-device-detect";
import {useDocumentTitle} from "../../services/DocumentTitle";

const PREFIX = 'SecurityConsulting';

const classes = {
    body: `${PREFIX}-body`,
    image: `${PREFIX}-image`,
    cardContainer: `${PREFIX}-cardContainer`
};

const Root = styled('div')(({theme}) => ({
    [`& .${classes.body}`]: {
        backgroundColor: theme.palette.background.default,
        maxWidth: "100%",
        display: "flex",
        padding: "25px 0 25px 0"
    },

    [`& .${classes.image}`]: {
        maxHeight: "40vh",
        float: "left",
        margin: "15px",
        maxWidth: "25%",
        [theme.breakpoints.down('lg')]: {
            maxHeight: "unset",
            maxWidth: "100%",
            float: "none",
            padding: "3px",
            margin: 0
        },
    },

    [`& .${classes.cardContainer}`]: {
        float: "right",
        margin: "12px",
        height: "100%",
        [theme.breakpoints.down('md')]: {
            display: "flex",
            justifyContent: "center"
        },
    }
}));

export default function SecurityConsulting() {

    useDocumentTitle("Sicherheitsberatung", "Dank unserer jahrzehntelangen Erfahrung im Bereich der IT Security sind wir bestens geeignet, um Ihre gesamte IT in Form eines umfassenden Security Audits genau zu prüfen. Lassen Sie sich von unseren unabhängigen Experten beraten und decken Sie bisher unbekannte Sicherheitslücken auf.")
    
    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Root>
            <PageHeader whiteTitle="Sicherheits"
                        blueTitle="beratung"
                        subtitle="Unsere Empfehlungen für Ihre Sicherheit"/>
            <div className={classes.body}>
                <Container maxWidth="lg">
                    <div>
                        {isMobileScreen ? '' :
                            <div className={classes.cardContainer}>
                                <SecurityConsultingCard/>
                            </div>
                        }
                        <Typography variant="h6">
                            <strong>
                                IT Security Audits
                            </strong>
                        </Typography>
                        <Typography variant="body1">
                            Dank unserer jahrzehntelangen Erfahrung im Bereich der IT Security sind wir bestens
                            geeignet, um Ihre gesamte IT in Form eines umfassenden Security Audits genau zu prüfen.
                            Lassen Sie sich von unseren unabhängigen Experten beraten und decken Sie bisher unbekannte
                            Sicherheitslücken auf.
                            <br/><br/>
                            Wenn Sie über besonders relevante Systeme verfügen lohnt es sich, diese einmal einem
                            spezifischen Audit zu unterziehen. Wir können für Sie Ihre Firewalls, Ihre Server und vieles
                            mehr genau prüfen
                            und Sie in der Optimierung Ihrer IT Security bestens beraten.
                            <br/><br/>
                            Des Weiteren bieten wir Ihnen die Möglichkeit, dass Sie für Ihr Unternehmen eine
                            strategische IT Security Beratung erhalten. So garantieren Sie, dass Ihre IT für die Zukunft
                            bestmöglich gewappnet
                            ist.
                            <br/><br/>
                        </Typography>

                        <div style={isSmallScreen ? {
                            display: "flex",
                            justifyContent: "center",
                        } : {width: "100%"}}>
                            <div className={classes.image}
                                 style={isIE ? {width: "100%", height: "100%", marginBottom: "20px"} : {}}>
                                <img alt="Security Beratung" src={Meeting} style={{width: "100%", height: "100%"}}/>
                            </div>

                        </div>

                        <Typography variant="h6">
                            <strong>
                                Security Assessments
                            </strong>
                        </Typography>
                        <Typography variant="body1">
                            Um Ihr Unternehmen effektiv gegen Angriffe in der IT zu schützen, können Sie eine eigens auf
                            Sie zugeschnittene IT Security Policy erstellen lassen.<br/>Ergänzend dazu verfassen wir für
                            Sie ein IT Security Handbuch, welches Sie Ihren Mitarbeitern aushändigen können. Damit
                            steigern Sie die Sensibilisierung für die Gefahr von Angriffen auf die IT, zum Beispiel in
                            den Bereichen Social Engineering und Phishing.<br/><br/>Auf Wunsch fertigen wir Ihnen auch
                            spezifische Komponenten, welche sich in Ihre IT Security Gesamtlösung einfügen
                            lassen.<br/><br/>
                        </Typography>

                        {isMobileScreen ?
                            <div className={classes.cardContainer}>
                                <SecurityConsultingCard/>
                            </div>
                            : ''
                        }

                        <Typography variant="h6">
                            <strong>
                                Implementation und Integration der IT Security Komponenten
                            </strong>
                        </Typography>
                        <Typography variant="body1">
                            Wir garantieren die Machbarkeit jeder von uns vorgeschlagenen IT Security Gesamt- und
                            Teil-Konzeption. Daher können wir auch bei deren Implementation und Integration federführend
                            sein oder sie in Ihrem Auftrag eigenständig verwirklichen.
                            <br/><br/>
                        </Typography>

                        <Typography variant="h6">
                            <strong>
                                Awareness Training für Führungskräfte
                            </strong>
                        </Typography>
                        <Typography variant="body1">
                            Da gerade die Führungskräfte von Firmen vermehrt mit sensitiven Daten in Berührung kommen,
                            legen wir grossen Wert darauf, dass sie im Umgang damit geschult sind. Dafür bieten wir
                            Sicherheitskurse und -Schulungen für Führungskräfte an, um gezielt deren Aufmerksamkeit und
                            Vorsicht zu fördern.
                            <br/><br/>
                        </Typography>

                        <Typography variant="h6">
                            <strong>
                                IT Security Wissensvermittlung
                            </strong>
                        </Typography>
                        <Typography variant="body1">
                            Bei Bedarf schulen wir Sie gerne in verschiedensten Bereichen der IT Security. Mögliche
                            Themen sind zum Beispiel Security Gateways, kryptographische Verfahren der IT Security
                            und viele weitere.
                        </Typography>
                    </div>
                </Container>
            </div>
        </Root>
    );
}